import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import { Grid } from '@material-ui/core';


const facultyData = [
  {
    name: "Mr. Suresh Ch. Haloi",
    designation: "SCIENTIFIC ASSISTANT",
    photo: require('./../assets/staff/suresh.jpeg'),
    retired: 0
  },
  {
    name: "Mr.Gokul Ch. Sarma",
    designation: "SCIENTIFIC ASSISTANT",
    photo: require('./../assets/staff/gokul.jpeg'),
    retired: 1
  },
  // {
  //   name: "Mr.Gagan Kalita",
  //   designation: "SCIENTIFIC ASSISTANT",
  //   photo: require('./../assets/staff/gagan.jpeg')
  // },
  {
    name: "Mr.Pradip Baishya",
    designation: "SCIENTIFIC ASSISTANT",
    photo: require('./../assets/staff/pradip.jpeg'),
    retired: 0
  }
]

const styles = {
  teacherCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    marginBottom: '20px',
    padding: '10px'
  },
  leftSide: {
    flexBasis: '80%',
    padding: '20px'
  },
  rightSide: {
    flexBasis: '20%'
  }
}

const TeacherCard = (props) => {
  return (
    <div style={styles.teacherCard}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={3} md={3} align="center">
          <img
            src={props.dp_url}
            alt=""
            style={{width: '50%'}}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={9} md={9}>
          <div style={styles.leftSide}>
            <h5>{props.name}</h5>
            <h6>{props.designation}</h6>
          </div>
        </Grid>
      </Grid>
      
      
    </div>
  )
}

export default class FacultyContainer extends Component {
  state={
    facultyData:[],
    rFacultyData: [],
    isLoaded:false,
    isRLoaded:false,
  }
  componentDidMount(){
    PostData('/getactiveemployeeforwebsitepost', {
      type: 'OFFICE'
    })
    .then(resp => {
      console.log("🚀 ~ file: StaffContainer.js ~ line 84 ~ FacultyContainer ~ componentDidMount ~ resp", resp)
      this.setState({
        	facultyData:resp,
        	isLoaded:true
      	})
    	}
    )


    PostData('/getactiveemployeeforwebsitepost', {
      type: 'RETIRED_STAFF'
    })
    .then(resp => {
      console.log("🚀 ~ file: StaffContainer.js ~ line 84 ~ FacultyContainer ~ componentDidMount ~ resp", resp)
      this.setState({
        rFacultyData:resp,
        isRLoaded:true
      	})
    	}
    )
  }

  getMyType = (type) => {
    if (type == 'SCIENTIFIC ASSISTANT'){
      return facultyData.filter(el => el.retired == 0);
    }else{
      let facultyData = this.state.facultyData;
      let r = facultyData.filter(el => el.designation == type && el.retired == 0);
      return r;
    }
    
  }


  getMyTypeR = (type) => {
    if (type == 'SCIENTIFIC ASSISTANT'){
      return facultyData.filter(el => el.retired == 1);
    }else{
      let facultyData = this.state.facultyData;
      let r = facultyData.filter(el => el.designation == type && el.retired == 1);
      return r;
    }
    
    
  }
  render() {
    return (
      <div>
        <Example bread={`Home / People / Staff List`} />
        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Departmental Staff`}
          />
          <br />

          <div>
            {this.state.facultyData.map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div>
          
          {/* <div>
            {this.getMyType('SCIENTIFIC ASSISTANT').map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div>



          <br />
          

          <div>
            {this.getMyType('PROGRAMMER CUM SYSTEM ANALYST').map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div>


          <br />
         
          <div>
            {this.getMyType('TECHNICAL ASSISTANT').map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div>


          <br />
          
          <div>
            {this.getMyType('GUEST INSTRUCTOR').map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div> */}



          <TitleStyle
            title={`Retired Staff`}
          />
          <br />
          <div>
            {this.state.rFacultyData.map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div>
          {/* <div>
            {this.getMyTypeR('SCIENTIFIC ASSISTANT').map((el, index) =>
              <TeacherCard
                key={index}
                {...el}
              />
            )}
          </div> */}

        </MDBContainer>

      </div>
    )
  }
}
