import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'

import {  withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class PatentContainer extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount(){
        GetData(`/getpatents`)
        .then((resp) => {
            this.setState({
                data: resp,
                isLoaded: true
            })
        })
    }

  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Patent`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Patents`}
          />
          <br />

        
            {this.state.isLoaded && <div>
                
                    <Table>

                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight: 700, color: 'black'}}>#</TableCell>
                                <TableCell style={{fontWeight: 700, color: 'black'}}>Name of the Inventors</TableCell>
                                <TableCell style={{fontWeight: 700, color: 'black'}}>Patent Title</TableCell>
                                <TableCell style={{fontWeight: 700, color: 'black'}}>Awarding Agency/Country</TableCell>
                                <TableCell style={{fontWeight: 700, color: 'black'}}>Date of Award/Published</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(this.state.data) && this.state.data.map((el,index) => <TableRow key={index}>

                                <TableCell>{index+1}</TableCell>
                                <TableCell>{el.inventors}</TableCell>
                                <TableCell>{el.title}</TableCell>
                                <TableCell>{el.agency}</TableCell>
                                <TableCell>{el.date}</TableCell>

                            </TableRow>)}
                        </TableBody>


                    </Table>
                </div>}

             

         




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(PatentContainer)
