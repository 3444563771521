import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import { Grid } from '@material-ui/core'


export default class FacultyInformationContainer extends Component {
  state = {
    teachers: [],
    isLoaded: true
  }
  componentDidMount() {
    GetData(`/getactiveemployeeforwebsite`)
      .then((resp) => {
        this.setState({
          teachers: resp,
          isLoaded: true
        })
      })

  }

  render() {
    return (
      <div>
        <Example bread={`Home / People / Faculty Information`} />
        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Faculty Information`}
          />
          <br />

         
            <iframe width={"100%"} height={"800px"} src="/PDFs/Information_of_Faculty_updated.pdf"></iframe>

          <br />


          



        </MDBContainer>

      </div>
    )
  }
}
