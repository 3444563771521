import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class Committee extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Committees`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
       
          

        
        <div>
        <p><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENTAL ADVISORY BOARD (DAB)</strong></span></span></p>
        <table width={590} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={40}>
                <p><span style={{fontSize: 'medium'}}>Principal, Assam Engineering College </span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>Chairman </span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={35}>
                <p><span style={{fontSize: 'medium'}}>Prof. Prabin K. Bora, EEE, IITG </span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>External Academician</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={36}>
                <p><span style={{fontSize: 'medium'}}>Prof. Sunandan Baruah, ECE, DBCET Guwahati </span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>External Academician/ Alumnus</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={27}>
                <p><span style={{fontSize: 'medium'}}>Shri Prabir K. Das, Director STPI, Guwahati </span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>Corporate representatives</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={35}>
                <p><span style={{fontSize: 'medium'}}>Shri Abhijit Bhuyan, MD, Bohniman Systems</span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>Industrial representatives/Alumnus</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={35}>
                <p><span style={{fontSize: 'medium'}}>Shri Tanmoy Goswami, Director, Sumato Global Tech.</span></p>
              </td>
              <td style={{background: 'transparent'}} width={223}>
                <p><span style={{fontSize: 'medium'}}>Industrial Representatives</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={337} height={34} colSpan={2}>
                <p><span style={{fontSize: 'medium'}}>All faculty members of the department of Electronics &amp; Telecommunication Engg. </span></p>
              </td>
              
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENTAL UNDERGRADUATE PROGRAM COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={31}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department&nbsp;</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>D.S. Pegu </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Apurba Kumar Kalita</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Dr. Gunajit Kalita (from CSE) </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Ruchira Mazumdar</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Kabindra Bhagawati </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={32}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286} height={31} colSpan={2}>
                <p><span style={{fontSize: 'medium'}}>One student each from 2</span><sup><span style={{fontSize: 'medium'}}>nd</span></sup><span style={{fontSize: 'medium'}}>, 3</span><sup><span style={{fontSize: 'medium'}}>rd</span></sup><span style={{fontSize: 'medium'}}> and 4</span><sup><span style={{fontSize: 'medium'}}>th</span></sup><span style={{fontSize: 'medium'}}> year Engineering Students.</span></p>
              </td>
              
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENTAL PHD MONITORING COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department&nbsp;</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>QUALITY CONTROL AND ACCREDITATION COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department&nbsp;</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>D.S. Pegu</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Apurba Kumar Kalita </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kabindra Bhagwati</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ruchira mazumdar </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>INDUSTRY- INSTITUTE COORDINATION AND COOPERATION COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>D.S. Pegu</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Coordinator</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Co-Coordinator</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Partha Bhowmik (From CSE department) </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENT INFRASTRUCTURE DEVELOPMENT AND MONITORING COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Partha Bhowmik (From the CSE department)</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>TIME-TABLE COORDINATION COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ruchira Mazumdar</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p align="left"><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENT OUTREACH COORDINATION COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Coordinator</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Partha Bhowmik (From CSE department)</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong> DEPARTMENT LIBRARY COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Riju Kalita</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kabindra Bhagwati </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ruchira Mazumdar</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENT WEBSITE COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Riju Kalita </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>STUDENT MENTORING COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>D.S. Pegu</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Apurba Kumar Kalita</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ruchira Mazumdar</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kabindra Bhagawati</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENTAL SANITIZATION AND HYGIENE COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DIFFERENTIALLY ABLE STUDENT MONITORING COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ruchira Mazumdar</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kabindra Bhagawati</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Kamini Kumari </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Riju Kalita </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>FACULTY ADVISORY COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Principal, AEC </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of ETE Dept., AEC</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of EE Dept., AEC </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of Electronics Communication, GU</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{textAlign: 'left'}} align="center"><span style={{color: '#4472c4'}}><span style={{fontSize: 'medium'}}><strong>DEPARTMENTAL EVENT ORGANIZATION COMMITTEE</strong></span></span></p>
        <table width={602} cellSpacing={0} cellPadding={7}>
          <tbody>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Head of the Department&nbsp;</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Chairperson</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Rashi Borgohain </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Secretary</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Dr. Gunajit Kalita</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Bijoy Goswami </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Ankur Jyoti Sarmah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Niranjan Jyoti Borah</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Nishant Bharti </span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
            <tr valign="top">
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Partha Bhowmik (From the CSE department)</span></p>
              </td>
              <td style={{background: 'transparent'}} width={286}>
                <p><span style={{fontSize: 'medium'}}>Member</span></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

             
          




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Committee)