import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer,Button, Alert, MDBBtn} from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import Snack from './Snack';

const facultyData = [
    {
      name: 'P. K. Saikia',
      discription: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
     
      
      photo: ''
    },
    {
        name: 'P. K. Saikia',
        discription: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
       
        
        photo: ''
      },
      {
        name: 'P. K. Saikia',
        discription: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
       
        
        photo: ''
      },
  ]
  
  const styles = {
    teacherCard: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#f9f9f9',
      marginBottom: '20px',
      padding: '10px'
    },
    leftSide: {
      flexBasis: '80%',
      padding: '20px'
    },
    rightSide: {
      flexBasis: '20%'
    }
  }
  
  const TeacherCard = (props) => {
    return (
      <div style={styles.teacherCard}>
        <div style={styles.leftSide}>
          <h3>{props.name}</h3>
          <h6>{props.discription}</h6>
         
  
          <Button
            color="primary"
            size="sm"
          >
            Read More
          </Button>
        </div>
        <div style={styles.rightSide}>
          <img
            alt=""
            src={require('./../assets/faculty/default.jpg')}
            style={{ width: '100%', borderRadius: '10px', boxShadow: '1px 2px 10px rgba(0,0,0,0.3)' }}
          />
        </div>
      </div>
    )
  }
  
  export default class FacultyContainer extends Component {
    state = {
      labs: [],
      isLoaded: false,
      isDownloading: false
    }

    componentDidMount() {
      GetData(`/getlaboratory`)
        .then((resp) => {
          //console.log(resp)
          this.setState({
            isLoaded: true,
            labs: resp
          })
        })
    }
    render() {
      return (
        <div>
          <Example />
          <MDBContainer style={{ paddingTop: '30px' }}>
            
           
            <SecondaryStyle
              title={`Laboratories`}
            />
            <div>
              {this.state.isLoaded ? (
                <div>
                  {this.state.labs.map((el,index) => 

                      <LabCard
                        key={index}
                        {...el}
                      />
                    
                    )}
                </div>
              ) : null}
            </div>
  
  
  
           
            
  
          </MDBContainer>
  
        </div>
      )
    }
  }
  

  class LabCard extends Component {
    state = {
      isDownloading: false
    }
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }


    showFile = (blob, index) => {



      var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
      var blobUrl = URL.createObjectURL(blob1);


      var link = document.createElement('a');
      link.href = blobUrl;
      //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
      link.click();

      this.setState({
        isDownloading: false
      })



    }

    handleClick(data, index) {

      this.setState({
        isDownloading: true
      })

      PostData(`/getfiledatafordownload`, data)
        .then((resp) => {

          //console.log(resp)
          this.showFile(resp, index)
        })

    }
    render(){
      return(
        <div>
          {this.state.isDownloading ? (<Snack open={true} message="Downloading . . . ." />) : null}
          <Alert color="primary">
            <h4 className="alert-heading">{this.props.lab_name}</h4>
            <p dangerouslySetInnerHTML={{ __html: this.props.description }} />
            <hr />

            Experiments: <p dangerouslySetInnerHTML={{ __html: this.props.lab_experiments }} />
            
            {this.props.lab_manual !== null ? (<div className="d-flex justify-content-end">
              {Array.isArray(this.props.lab_manual) && this.props.lab_manual.length > 0 ? (
                <div>
                  {this.props.lab_manual.map((el1, index1) =>
                    <MDBBtn
                      size="sm"
                      color="blue-grey"
                      style={{ color: 'white' }}
                      key={index1}
                      onClick={this.handleClick.bind(this, el1, 0)}
                    >
                      <i className="fa fa-paperclip"></i> LAB MANUAL {index1+1}
                                        </MDBBtn>)}
                </div>
              ) : null}
            </div>) : null}
          </Alert>
        </div>
      )
    }
  }