import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class History extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / History`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
        <TitleStyle
            title={`History of the Department`}
          />
          <p align="justify" style={{ fontSize: '1.1em' }}>
          Established in 1983 with an annual intake of 15 students, it is the first in this field to provide the Bachelor of Engineering (B.E.) degree in Electronics & Telecommunication Engineering under the Director of Technical Education, Govt of Assam. The department is affiliated with Gauhati University prior to getting affiliated with Assam Science and Technology University (ASTU) in the year 2017.  The annual intake of the department increased to 30 in the year 1996 and finally a total of 60 students in 2007. Continuing with the ICT roadmap, the department started its Ph.D. program in 2014.  The department offers a syllabus covering the major thrust areas in the field of Electronics and Telecommunication.
          </p>
          <br />

        


             
          




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(History)