import React, { Component } from 'react'
import { Container, Badge } from 'mdbreact';

const FlashNews = [
  {
    title: 'New News',
    path: '/notifications'
  },
  {
    title: 'New News 2',
    path: '/notifications'
  }
]

const style = {
  display:'flex',
  flexDirection: 'row',
  width: '100%'
}

const containerStyle = {
  container: {
    backgroundColor: '#f9f9f9',
    marginTop: '5px',
    padding: '8px',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  firsDiv: {
    
  },
  secondDiv: {
    flexBasis: '90%'
  }
}

class FleshNews extends Component {
  state = {

  }
  render() {
    return (
      <div>
        <Container style={containerStyle.container}>
        <div style={containerStyle.firsDiv}>
            <Badge color="primary" >Notices</Badge>
        </div>

          <div style={containerStyle.secondDiv}>
            <marquee>
              <div style={style}>
                {FlashNews.map((el, index) =>
                  <div style={{ paddingRight: '20px' }}>
                    * {el.title}
                  </div>
                )}
              </div>
            </marquee>
           </div> 
          
        </Container>
      </div>
    );
  }
}
export default FleshNews ;