import React, { Component } from 'react';
import MultiNavBar from '../component/MultiNavBar/MultiNavBar';

class TestContainer extends Component {
    render() {
        return (
            <div>
                <MultiNavBar
                    width={`200px`}
                    color={`white`}
                    backgroundOfNav={`lighgrey`}
                    backgroundColor={`brown`}
                    history={this.props.history}
                />
            </div>
        );
    }
}

export default TestContainer;