import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class ResearchScholars extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Research Scholars`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Research Scholars`}
          />
          <br />



          <table className="table table-sm table-bordered" cellpadding="7" cellspacing="0" width="616">
            <tbody>
              <tr>
                <td height="16" width="5.084745762711864%">
                  <p><strong>Research Guide</strong></p>
                </td>
                <td width="29.566854990583803%">
                  <p><strong>Name of the Scholar</strong></p>
                </td>
                <td width="21.468926553672315%">
                  <p><strong>Topic of the Research</strong></p>
                </td>
                <td width="21.657250470809792%">
                  <p><strong>University & Year of Registration</strong></p>
                </td>
                <td width="10.546139359698682%">
                  <p><strong>Status</strong></p>
                </td>

              </tr>
              <tr>
                <td rowSpan={6} height="44" width="5.084745762711864%">
                  <p>Dr. Navajit Saikia</p>
                </td>
                <td valign="top" width="29.566854990583803%">
                  <p>Gunajit Kalita</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Synthesis of Reversible Logic Circuit</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University <br />YoR-2014 <br />Reg No- Engg. 25/14</p>
                </td>
                <td width="10.546139359698682%">
                  <p>Completed</p>
                </td>
              </tr>
              <tr>

                <td valign="top" width="29.566854990583803%">
                  <p>Gautam Chakravarty</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Automatic Speech Recognition: Human Computer Interface for Sylheti language</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University <br />YoR-2014 <br />Reg No- Engg. 29/14</p>
                </td>
                <td width="10.546139359698682%">
                  <p>Completed</p>
                </td>
              </tr>

              <tr>

                <td valign="top" width="29.566854990583803%">
                  <p>Amlan Jyoti Das</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Features and Detectors for Video Surveillance</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University<br />
                    YoR-2014<br />
                    Reg. No- ECE-11/14</p>
                </td>
                <td width="10.546139359698682%">
                  <p>Completed</p>
                </td>
              </tr>

              <tr>

                <td valign="top" width="29.566854990583803%">
                  <p>Simantika Choudhury</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Video Surveillance using Learning based
                    techniques</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University<br />
                    YoR-2018<br />
                    Reg. No- ECE-02/18</p>
                </td>
                <td width="10.546139359698682%">
                  <p>On going</p>
                </td>
              </tr>

              <tr>

                <td valign="top" width="29.566854990583803%">
                  <p>Aparajita Das</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Human Detection & Action Recognition for Surveillance Applications</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University<br />
                    YoR-2018</p>
                </td>
                <td width="10.546139359698682%">
                  <p>On going</p>
                </td>
              </tr>

              <tr>

                <td valign="top" width="29.566854990583803%">
                  <p>Lakhyadeep Konwar</p>
                </td>
                <td valign="top" width="21.468926553672315%">
                  <p>Hand Gesture Recognition for Virtual Reality Application</p>
                </td>
                <td valign="top" width="21.657250470809792%">
                  <p>Gauhati University<br />
                    YoR-2019<br />
                    Reg. No- PA-181-839-0001 year 2019</p>
                </td>
                <td width="10.546139359698682%">
                  <p>On going</p>
                </td>
              </tr>




            </tbody>
          </table>







        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(ResearchScholars)
