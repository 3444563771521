import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class ResearchLaboratories extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Research Laboratories`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Research Laboratories`}
          />
          <br />


            <iframe src="/data/r_l.pdf" width="100%" height="800px">

            </iframe>






        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(ResearchLaboratories)
