import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'
import FeedbackContainer from '../component/FeedbackContainer'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class Pes extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / PEO`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
        <TitleStyle
            title={`Program Educational Objectives (PEO)`}
          />
          <ol className="list-unstyled">
            <li>
              1. Graduates will attain sound theoretical and practical knowledge for pursuing a career in industry and research and development organizations or carrying out higher studies.
                </li>
            <li>
              2. Graduates will possess skills and creativity essential for innovation, entrepreneurship, and the creation of intellectual property.
                </li>
            <li>
              3. Graduates will imbibe human values, team work and social and professional ethics for building a better environment and society


                </li>

          </ol>

          <br />

        


             
          




        </MDBContainer>

  
      </div>
    )
  }
}

export default withStyles(styles)(Pes)