import { Button, Card, CardContent, CardHeader, Container, Grid, MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import { Form } from 'reactstrap'
import { GetData, PostData } from '../api/service'

export default function FeedbackContainer() {

    const [state, setState] = React.useState({
        name: "",
        email: "",
        phone: "",
        designation: "",
        associated_as: "",
        associated_as_other: "",
        subject: "",
        message: ""
    })


    const [error, setError] = React.useState({
        name: "",
        email: "",
        phone: "",
        designation: "",
        associated_as: "",
        associated_as_other: "",
        subject: "",
        message: ""
    })


    const [data, setData] = React.useState([])


    React.useEffect(() => {
        GetData(`/getfeedbacksubject`)
        .then((resp) => {
            setData(resp)
        })
    }, [])

    function onChange(name, value) {
        setState({
            ...state,
            [name]: value
        })
    }



    function handleValidation() {
        let isValid = true;
        let err = [];


        if (state.name == "") {
            isValid = false;
            err['name'] = "Field Must not be Empty"
        } else {
            err['name'] = "";
        }

        if (state.email == "") {
            isValid = false;
            err['email'] = "Field Must not be Empty"
        } else {
            err['email'] = "";
        }

        if (state.phone == "") {
            isValid = false;
            err['phone'] = "Field Must not be Empty"
        } else {
            err['phone'] = "";
        }


        if (state.designation == "") {
            isValid = false;
            err['designation'] = "Field Must not be Empty"
        } else {
            err['designation'] = "";
        }


        if (state.associated_as == "") {
            isValid = false;
            err['associated_as'] = "Field Must not be Empty"
        } else {
            err['associated_as'] = "";
        }

        if(state.associated_as == "OTHER"){
            if (state.associated_as_other == "") {
                isValid = false;
                err['associated_as_other'] = "Field Must not be Empty"
            } else {
                err['associated_as_other'] = "";
            }
    
        }


        if (state.subject == "") {
            isValid = false;
            err['subject'] = "Field Must not be Empty"
        } else {
            err['subject'] = "";
        }


        if (state.message == "") {
            isValid = false;
            err['message'] = "Field Must not be Empty"
        } else {
            err['message'] = ""
        }

        setError([...err])

        return isValid;



    }


    function onSubmit(e) {
        e.preventDefault();
        if (handleValidation()) {
            PostData(`/createfeedback`, state)
                .then((resp) => {
                    alert(resp)
                    setState({
                        name: "",
                        email: "",
                        phone: "",
                        designation: "",
                        associated_as: "",
                        associated_as_other: "",
                        subject: "",
                        message: ""
                    })
                })
        }
    }

    return (
        <div style={{ backgroundColor: '#f9f9f9' }}>
            <form onSubmit={onSubmit}>
                <Container>
                    <div>
                        <CardHeader
                            title="Submit your feedback"
                            subheader="Fill up the form below and submit your details. You will be contacted by the authority soon."
                        />

                        <CardContent>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Your Name"
                                        value={state.name}
                                        onChange={(e) => onChange("name", e.target.value)}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Your Email ID"

                                        variant="outlined"
                                        value={state.email}
                                        onChange={(e) => onChange("email", e.target.value)}
                                        type="email"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Your Phone No"
                                        variant="outlined"
                                        value={state.phone}
                                        onChange={(e) => onChange("phone", e.target.value)}
                                        type="number"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="Your Designation"
                                        variant="outlined"
                                        value={state.designation}
                                        onChange={(e) => onChange("designation", e.target.value)}
                                        type="text"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    

                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        label="Associated as"
                                        value={state.associated_as}
                                        onChange={(e) => onChange("associated_as", e.target.value)}
                                        helperText="Associated as"
                                    >
                                        {["STUDENT","ALUMNI","OTHER"].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {state.associated_as == "OTHER" &&  <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="outlined-required"
                                        label="State your association"
                                        variant="outlined"
                                        value={state.associated_as_other}
                                        onChange={(e) => onChange("associated_as_other", e.target.value)}
                                        type="text"
                                    />
                                </Grid>}

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    

                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        fullWidth
                                        variant="outlined"
                                        label="Select Subject"
                                        value={state.subject}
                                        onChange={(e) => onChange("subject", e.target.value)}
                                        helperText="Please select your Subject"
                                    >
                                        {data.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        required
                                        id="outlined-required"
                                        label="Message"
                                        variant="outlined"
                                        value={state.message}
                                        onChange={(e) => onChange("message", e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                    <Button type="submit" color="primary" variant="contained">Submit Feedback</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </div>
                </Container>
            </form>
        </div>
    )
}
