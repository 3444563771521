import React, { Component } from 'react'
import { Container } from 'mdbreact';


export default class ContactUsContainer extends Component {
  render() {
    return (
      <div>
          <Container>
              <div>
                  <h3 style={{textAlign:'center', paddingTop:'5vh'}}>CONTACT US</h3>
              </div>
              <hr></hr>
              <br></br>
              <div>
            <ul >
                <li style={{ paddingBottom: '5vh' }}>
                    <b>Postal Address</b><br />
                    Paschim Guwahati Mahavidyalaya,<br />
                    Amrit Bharali Path<br />
                    Village: Dharapur,<br />
                    P.O. &amp; P.S.-Azara, Guwahati – 781017,<br />
                    Dist.-Kamrup(M), Assam.<br />
                    email: pgmahavidyalaya@rediffmail.com<br />
                    Website: www.pgmdharapur.ac.in <br />
                    Phone: +91361285405 (M)<br />
                    Fax: +91361285405

                </li>
                <li style={{ paddingBottom: '5vh' }}>
                    <b>Principal: </b><br />
                    Dr. Rana Sarmah ,<br />
                    Email: pgmmahavidylaya@rediffmail.com,<br />
                    Phones: +91-95314-75684

                </li>
                </ul>
                </div>
          </Container>
      </div>
    )
  }
}
