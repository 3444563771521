import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';


const navigationArray = [
  {
    title: 'Home',
    path: '/',
    isDropDown: false,
    dropDownItem: []
  },
  {
    title: 'About',
    path: '',
    isDropDown: true,
    dropDownItem: [
      // {
      //   title: 'History',
      //   path: '/history',
      //   isDropDown: false,
      //   dropDownItem: []
      // },  
      {
        title: 'Committees',
        path: '/committee',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'PO',
      //   path: '/po',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      {
        title: 'PEO',
        path: '/pes',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'PSO',
        path: '/pso',
        isDropDown: false,
        dropDownItem: []
      },  
      



    ]
  },
  {
    title: 'Academics',
    path: '',
    isDropDown: true,
    dropDownItem: [
      {
        title: 'B.Tech. Syllabus',
        path: '/btech',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Academic Calendar',
        path: '/acalender',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Time Table',
        path: '/timetable',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'List of Holidays',
        path: '/holidaylist',
        isDropDown: false,
        dropDownItem: []
      },  
      {
        title: 'Laboratories',
        path: '/laboratory',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Projects',
        path: '/projects',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Course Materials',
      //   path: '/coursematerials',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      {
        title: 'Best Practices',
        path: '/bestpractices',
        isDropDown: false,
        dropDownItem: []
      },



    ]
  },
  

  {
    title: 'People',
    path: '',
    isDropDown: 'true',
    dropDownItem: [
    //  {
    //     title: 'HOD',
    //     path: '/messagefromhod',
    //     isDropDown: false,
    //     dropDownItem: []
    //   },
      {
        title: 'Faculty List',
        path: '/faculty',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Faculty Information',
      //   path: '/facultyinfo',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      {
        title: 'List of Hods',
        path: '/hod',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Retired Faculty',
        path: '/formerfaculty',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Office Staff',
        path: '/staff',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Student',
      //   path: '/student',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      // {
      //   title: 'Former Faculty',
      //   path: '/formerfaculty',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
       {
         title: 'Alumni',
        path: '/alumni',
         isDropDown: false,
         dropDownItem: []
       }
    ]
  },
  // {
  //   title: 'Newsletters',
  //   path: '/newsletter',
  //   isDropDown: false,
  //   dropDownItem: []
  // },
  // {
  //   title: 'Notifications',
  //   path: '/notifications',
  //   isDropDown: false,
  //   dropDownItem: []
  // },
  {
    title: 'Research',
    path: '/facultypublications',
    isDropDown: true,
    dropDownItem: [
      {
        title: 'Publications',
        path: '/facultypublications',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Sponsored Projects',
        path: '/sponsoredprojects',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Research Scholars',
        path: '/researchscholars',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Patents',
        path: '/patents',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Collaborations',
        path: '/collaborations',
        isDropDown: false,
        dropDownItem: []
      },
       {
        title: 'Research Laboratories',
        path: '/researchlab',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Workshop',
      //   path: '/events/workshop',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      // {
      //   title: 'Talks',
      //   path: '/events/talks',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      // {
      //   title: 'Seminars',
      //   path: '/events/seminars',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      // {
      //   title: 'Conference',
      //   path: '/events/conference',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      // {
      //   title: 'Faculty Development Program',
      //   path: '/events/fdp',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
    ]
  },
  // {
  //   title: 'Achievements',
  //   path: '/achievements',
  //   isDropDown: false,
  //   dropDownItem: []
  // },
  {
    title: 'Events',
    path: '/events',
    isDropDown: true,
    dropDownItem: [
      {
        title: 'Workshop',
        path: '/events/workshop',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Talks',
        path: '/events/talks',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Seminars',
        path: '/events/seminars',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Conference',
      //   path: '/events/conference',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      {
        title: 'Faculty Development Program',
        path: '/events/fdp',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Webinar',
        path: '/events/webiner',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Lecture Series',
        path: '/events/lectureseries',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Recent Events',
        path: '/events/recent',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Other',
        path: '/events/other',
        isDropDown: false,
        dropDownItem: []
      },
    ]
  },
  {
    title: 'Student Zone',
    path: '',
    isDropDown: true,
    dropDownItem: [
      {
        title: 'Internship',
        path: '/training',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Placement',
        path: '/placement',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Notice Board',
      //   path: '/notifications',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      {
        title: 'Newsletters',
        path: '/newsletter',
        isDropDown: false,
        dropDownItem: []
      },
      {
        title: 'Student Achievement',
        path: '/achievements',
        isDropDown: false,
        dropDownItem: []
      },
      // {
      //   title: 'Student Activity',
      //   path: '/activities',
      //   isDropDown: false,
      //   dropDownItem: []
      // },
      
    ]
  },
  {
    title: 'Notice Board',
    path: '/notifications',
    isDropDown: false,
    dropDownItem: []
  },
  {
    title: 'Gallery',
    path: '/gallery',
    isDropDown: false,
    dropDownItem: []
  },
  {
    title: 'Useful Links',
    path: '/usefullinks',
    isDropDown: false,
    dropDownItem: []
  },
  {
    title: 'Course Materials',
    path: '/coursematerials',
    isDropDown: false,
    dropDownItem: []
  },
]

export default class SubNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <div className="subnavmargin">
          <Navbar style={{ backgroundColor: 'rgb(48 135 131)' }} expand="md" fixed={this.props.sticky ? 'top' : ''}
          >
            <NavbarToggler onClick={this.toggle}>
            <i className="fa fa-bars text-white"></i>
            </NavbarToggler>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mx-auto fa-1x " navbar >
                {navigationArray.map((el, index) => {

                  if (el.isDropDown) {
                    return (
                      <UncontrolledDropdown nav inNavbar key={index} style={{ paddingRight: '6px', paddingLeft: '6px' }}>

                        <DropdownToggle nav caret style={{ color: 'white', fontSize: '1em', fontWeight: 700}}>
                          {el.title}
                        </DropdownToggle>
                        <DropdownMenu right>

                          {el.dropDownItem.map((el1, index1) => {
                            if (el1.isDropDown) {
                              return (
                                <DropdownItem toggle={false} key={index1}>

                                  <RightDropdown
                                    title={el1.title}
                                    dropDownItem={el1.dropDownItem}
                                  />


                                </DropdownItem>
                              )
                            } else {
                              return (
                                <DropdownItem key={index1} style={{ padding: '5px' }}>
                                  <NavLink
                                    href={el1.path}
                                    style={{ 
                                      padding: '4px',
                                      fontSize: '0.8em',
                                      color: 'black',
                                      fontWeight: 700
                                    }}
                                  >
                                    {el1.title}
                                  </NavLink>
                                </DropdownItem>
                              )
                            }
                          })}



                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )
                  } else {
                    return (
                      <NavItem  key={index} style={{ paddingRight: '6px', paddingLeft: '6px' }}>
                        <NavLink
                          className="navitems"
                          href={el.path}
                          style={{fontSize:'1em', fontWeight: 700}}
                          

                        >{el.title}</NavLink>
                      </NavItem>
                    )
                  }

                }
                )}

              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}
class RightDropdown extends Component {
  state = {
    btnDropright: false
  }
  render() {
    return (
      <Dropdown direction="right" isOpen={this.state.btnDropright} toggle={() => { this.setState({ btnDropright: !this.state.btnDropright }); }}>
        <DropdownToggle caret tag="a">
          {this.props.title}
        </DropdownToggle>
        <DropdownMenu>
          {this.props.dropDownItem.map((el, index) => {
            return (
              <DropdownItem
              >
                <NavLink
                  href={el.path}
                >
                  {el.title}
                </NavLink>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>

    )
  }
}