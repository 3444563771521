import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBBtn, Button } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData } from '../api/service';


const tableData = [
    {
        name: 'Pranjal Saikia',
        award: 'Something',
        year: '2018'
    },
    {
        name: 'Pranjal Saikia',
        award: 'Something',
        year: '2019'
    },
    {
        name: 'Pranjal Saikia',
        award: 'Something',
        year: '2019'
    }
]

export default class AlumniContainer extends Component {
    state = {
        tableData: [],
        tableData1: [],
        isLoaded: false
    }
    componentDidMount() {
        GetData('/getalumni')
            .then(resp => {

                if (Array.isArray(resp)) {
                    this.setState({
                        tableData: resp,
                        isLoaded: true
                    })
                }

            })
    }
    render() {
        return (
            <div>
                <Example bread={`Home / People / Alumni`} />
                <MDBContainer style={{ padding: '20px' }}>
                    <div>
                        <TitleStyle
                            title={`Our Alumni List`}
                        />
                        <br />

                        

                    </div>
                    <div>

                        <TableStyle
                            data={this.state.tableData}
                        />



                    </div>



                </MDBContainer>
            </div>
        )
    }
}


const TableStyle = (props) => {
    return (
        <div>
            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Batch</th>
                        <th>Photo</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {props.data.map((el, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.batch}</td>
                            <td>{el.current_location}</td>
                            <td>{el.photo}</td>
                        </tr>
                    )}
                </MDBTableBody>
            </MDBTable>

            <div align="right">
                <Button
                    size="sm"
                    color="primary"

                >
                    Click to get More
              </Button>
            </div>
        </div>
    )
}