import React from 'react'
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Example from '../component/RecentNews'

export default function TpSummaryContainer() {

   

    
    return (
        <div>
            <Example bread={`Home / Placement Summary`} />
            <Container style={{ padding: '20px' }}>
                
            <iframe src="https://drive.google.com/file/d/1eZn-v8kDAnsy5p4f_qN3_0Qk8v8ZbBHo/preview" width="100%" height="1300"></iframe>
            </Container>
        </div>
    )
}

