import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import { Grid } from '@material-ui/core'

const facultyData = [
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E., AEC, Ghy',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  },
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E.',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  },
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E.',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  }
]

const styles = {
  teacherCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    marginBottom: '20px',
    padding: '10px'
  },
  leftSide: {
    flexBasis: '80%',
    padding: '0px'
  },
  rightSide: {
    flexBasis: '20%'
  },
  smallText: {
    color: 'grey',
    marginBottom: '5px',
    marginTop: '5px',
    fontSize: '0.9em'
  },
  smallTextH6: {
    marginBottom: '10px'
  }
}

class TeacherCard extends Component {

  state = {
    dp: '',
    cv: '',
    isLoading: false,
    isLoaded: false
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  showFile = (blob) => {



    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);


    var link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${blob.type}_${Math.random() * 1000}.pdf`;
    link.click();




  }



  componentDidMount() {

    let data = this.props.dp;
    if (Array.isArray(data) && data.length > 0) {
      PostData(`/getfiledata`, data[0])
        .then((resp) => {
          this.setState({
            dp: resp,
            isLoaded: true
          })
        })
    }
  }



  showQualifications = (data, type) => {
    return data.filter(el => el.type == type)
  }

  render() {
    let i = '';
    if (this.state.isLoaded) {

      i = `data:${this.state.dp.mime};base64,${this.state.dp.data}`;

    }
    return (
      <div style={styles.teacherCard}>
        <Grid container>
          <Grid item sm="12" lg="4" md="4" xs="12">
            {this.state.isLoaded ? (
              <img
                alt=""
                src={i}
                style={{ width: '70%', height: '70%', boxShadow: '1px 2px 10px rgba(0,0,0,0.3)' }}
              />
            ) : (
              <img
                alt=""
                src={require('./../assets/faculty/default.jpg')}
                style={{ width: '70%', height: '70%', boxShadow: '1px 2px 10px rgba(0,0,0,0.3)' }}
              />
            )}
          </Grid>

          <Grid item sm="12" lg="4" md="4" xs="12">
            <h4>{this.props.name}</h4>

            <h6 style={styles.smallTextH6}>

              {this.props.designation}</h6>

            <h6 style={styles.smallTextH6}>
              <div style={styles.smallText}>
                SPECIALIZATION
              </div>
              {this.props.specialisation}</h6>

            <h6 style={styles.smallTextH6}>
              <div style={styles.smallText}>
                EMAIL
              </div>
              <a href={`mailto:${this.props.email}`}>{this.props.email}</a></h6>
            <div>
              {this.props.cv_url != "" && <a
                size="sm"
                color="primary"
                style={{ cursor: 'pointer', color: 'blue', fontSize: 12 }}
                target="_blank" href={this.props.cv_url}><u>View Profile</u></a>}

            </div>
            


            {/* <MDBCol sm="12" lg="6" md="6" xs="12"> */}
            {/* <h6 style={styles.smallTextH6} >
                <div style={styles.smallText}>
                  TEACHING
                </div>
                </h6> */}

            {/* <MDBRow>
                  <MDBCol lg="6">

                  <h6 style={styles.smallTextH6}>
                      January - June
                  </h6>

                  <ul style={{marginLeft: '30px'}}>
                    {this.showQualifications(this.props.qualification, 'EVEN').map((el, index) =>
                      <li key={index} style={{ fontSize: '0.8em' }}>{el.details}</li>
                    )}
                  </ul>

                 

                  <h6 style={styles.smallTextH6} >
                   
                      July - December
                
                  </h6>

                  <ul style={{ marginLeft: '30px' }}>
                    {this.showQualifications(this.props.qualification,'ODD').map((el,index) => 
                      <li key={index} style={{ fontSize: '0.8em' }}>{el.details}</li>
                    )}
                    
                    
                  </ul>

                </MDBCol>
                </MDBRow> */}

            {/* <h6 style={styles.smallTextH6}> 
                <div style={styles.smallText}>
                  HIGHEST DEGREE RECEIVED
                </div>
                {this.showQualifications(this.props.qualification)}</h6>  */}




            {/* </MDBCol> */}







          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
          {this.props.date_of_appointment != "" && this.props.date_of_appointment != null && <div style={{marginTop: 35}}>
              <h6 style={styles.smallTextH6}>
                <div style={styles.smallText}>
                  DATE OF APPOINTMENT
                </div>
                {this.props.date_of_appointment}</h6>
            </div>}
          </Grid>
        </Grid>


      </div>
    )
  }

}

export default class FacultyContainer extends Component {
  state = {
    teachers: [],
    isLoaded: true
  }
  componentDidMount() {
    GetData(`/getactiveemployeeforwebsite`)
      .then((resp) => {
        this.setState({
          teachers: resp,
          isLoaded: true
        })
      })

  }

  render() {
    return (
      <div>
        <Example bread={`Home / People / Faculty`} />
        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Faculties`}
          />
          <br />

          {this.state.isLoaded ? (<Grid container spacing={2}>
            {this.state.teachers.map((el, index) =>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TeacherCard
                  key={index}
                  {...el}
                />
              </Grid>
            )}
          </Grid>) : null}


          <br />


          {/* <TitleStyle
            title={`Publications`}
          />
          <br />

          <p><span lang="en-US"><strong>D.S. Pegu</strong></span></p>
<p lang="en-US">&nbsp;<span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">1. D. Hazarika and D.S. Pegu, &ldquo;Micro-controller based air pressure monitoring instrumentation system using optical fiber as sensor&rdquo;, </span><span lang="en-US"><em>Optical Fiber Technology 19 (2013)83-87</em></span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Dr. Navajit Saikia</strong></span></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">Prabin K. Bora and Navajit Saikia &ldquo;Image Retrieval Using One-Sided Linear Prediction Based Texture Modelling &ldquo;, ICVGIP 2002, </span><span lang="en-US"><em>Proceedings of the Third Indian Conference on Computer Vision, Graphics &amp; Image Processing</em></span><span lang="en-US">, Ahmadabad, India, December 16-18, 2002</span></p>
<p><span lang="sv-SE">Navajit Saikia, Prabin K. Bora</span><span lang="en-US"> &ldquo;Retrieving Video in Wavelet Compressed Domain&rdquo;,</span><span lang="en-US"><em>8</em></span><sup><span lang="en-US"><em>th</em></span></sup><span lang="en-US"><em> International Conference on Information Technology</em></span><span lang="en-US"> &ndash;, Bhubaneswar, Dec 20-23, 2005, </span></p>
<p><span lang="sv-SE">Navajit Saikia, Prabin K. Bora</span><span lang="en-US"> &ldquo;</span><span lang="en-US">Video Authentication Using Temporal Wavelet Transform</span><span lang="en-US"><strong>&rdquo; </strong></span><span lang="en-US"><em><strong>- </strong></em></span><span lang="en-US"><em>15th International Conference on Advanced Computing and Communications (ADCOM 2007)</em></span></p>
<p><span lang="sv-SE">Navajit Saikia, Prabin K. Bora</span><span lang="en-US">&ldquo;</span><span lang="en-US">A hybrid algorithm for video authentication&rdquo; - </span><span lang="en-US"><em>National Conference on Communications (NCC)2008</em></span><span lang="en-US"> Jan. 2008,</span></p>
<p><span lang="sv-SE">Navajit Saikia, Prabin K. Bora</span><span lang="en-US">&ldquo;</span><span lang="en-US">Robust video hashing using the 3D-DWT</span><span lang="en-US"><strong> &ldquo;-</strong></span><span lang="en-US"><em>National Conference on Communications (NCC)2011 </em></span><span lang="en-US">on - 28-30 Jan. 2011, IEEE</span></p>
<p><span lang="sv-SE">Navajit Saikia, Prabin K. Bora</span><span lang="en-US">&ldquo;</span><span lang="en-US">Perceptual hash function for scalable video&rdquo; - </span><span lang="en-US">February 2014</span><span lang="en-US"><em>, International Journal of Information Security 13(1),</em></span><span lang="en-US"> Springer DOI 10.1007/s10207-013-0211-z</span></p>
<p><span lang="sv-SE">Navajit Saikia &rdquo;</span><span lang="en-US">Perceptual hashing in the 3D-DWT domain&rdquo;- </span><span lang="en-US"><em>International Conference on Green Computing and Internet of Things (ICGCIoT), 2015</em></span><span lang="en-US"> - 8-10 Oct. 2015 IEEE</span></p>
<p><span lang="en-US">Gunajit&nbsp;Kalita,&nbsp;Navajit&nbsp;Saikia, "Reversible Comparator circuit using a new Reversible Gate",&nbsp;</span><span lang="en-US"><em>Proc. Of 6</em></span><sup><span lang="en-US"><em>th</em></span></sup><span lang="en-US"><em>&nbsp;International Conference on Computer Communication Technology 25th - 27th&nbsp;Sep, 2015, Published by ACM</em></span></p>
<p><span lang="en-US">Gunajit&nbsp;Kalita,&nbsp;Navajit&nbsp;Saikia,&nbsp;"Designing reversible arithmetic, logic circuit to implement micro-operation in quantum computation",&nbsp;</span><span lang="en-US"><em>XXVII IUPAP Conference on Computational Physics, IIT Guwahati, (CCP2015) Published IOP in Journal of Physics: Conference Series759(2016) 012097</em></span><span lang="en-US">&nbsp;</span></p>
<p><span lang="en-US">A. J. Das and N.Saikia, &ldquo;Pedestrian Detection using Dense LDB descriptor combined with HOG, &rdquo;</span><span lang="en-US"><em>&nbsp;in IEEE International Conference on Information Technology (InCITe 2016), Amity University,&nbsp;6th-7th Oct. 2016, Noida, India.</em></span></p>
<p><span lang="en-US">Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia and Kandarpa Kumar Sarma, "Recurrent Neural Network Based Approach to Recognise Isolated Digits in Sylheti Language Using MFCC Features",&nbsp;</span><span lang="en-US"><em>Proceedings of International Conference On Telecommunication, Power Analysis And Computing Techniques (ICTPACT) &ndash; 2017, ISBN: 978-1-5090-3381-2, 6th &ndash; 8th April 2017.</em></span></p>
<p><span lang="en-US">Gautam Chakraborty and Navajit Saikia, &ldquo;A Survey on Automatic Speech Recognition with special reference to Sylheti Language&rdquo;, </span><span lang="en-US"><em>Proceedings of 5th International Conference on Computing for Sustainable Global Development, March 14-16, 2018.</em></span></p>
<p><span lang="en-US">Amlan Jyoti Das and Navajit Saikia, &ldquo;Design of pedestrian detectors using combinations of scale spaces and classifiers&rdquo;, in </span><span lang="en-US"><em>Elsevier Journal of King Saud University &ndash; Computer and Information Sciences</em></span><span lang="en-US">, (in press), https://doi.org/10.1016/j.jksuci.2019.06.017.</span></p>
<p><span lang="en-US">Gautam Chakraborty and Navajit Saikia, &ldquo;Speech Recognition of Isolated Words using a New Speech Database in Sylheti&rdquo;, in </span><span lang="en-US"><em>International Journal of Recent Technology and Engineering,</em></span><span lang="en-US"> vol. 8, no. 3, Sep-2019, DOI: 10.35940/ijrte.C5874.098319.</span></p>
<p><span lang="en-US">Simantika Choudhury, Amlan Jyoti Das and Navajit Saikia, &ldquo;Recent trends in learning-based techniques for human and animal detection&rdquo;, </span><span lang="en-US"><em>Proceedings of National Conference on emerging computing technologies and its applications</em></span><span lang="en-US"> (NCECTA) - 2019, April 26-27, 2019.</span></p>
<p><span lang="en-US">G.Chakraborty, M. Sharma, N. Saikia, K.K.Sarma and N.E. Mastorakis, "Speech Database in Sylheti and Speech Recognition using Convolutional Neural Network", </span><span lang="en-US"><em>24th International Conference on Circuits, Systems, Communication and Computers Chania</em></span><span lang="en-US">, Crete Island, Greece, July 19-22, 2020. (Accepted)</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Book Chapter publication</strong></span></p>
<p><span lang="en-US">A. J. Das, N. Saikia, K. K. Sarma,&nbsp;&ldquo;Object classification and tracking in real-time: An overview&rdquo;</span><span lang="en-US"><strong>&nbsp;</strong></span><span lang="en-US"><em>in&nbsp;Emerging Technologies in Intelligent Applications for Image and Video Processing, </em></span><span lang="en-US">Chapter-11, pp. 250-295, </span><span lang="en-US">IGI Global, USA, 2016.</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Dr. Rashi Borgohain</strong></span>&nbsp;</p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">R. Borgohain, S. Sharma, and J. C. Dutta "Modelling Cylindrical nano size ISFET for Biosensor applications" </span><span lang="en-US"><em>Proc. of CODEC-06, Kolkata University, India, Dec. 2006.</em></span></p>
<p><span lang="en-US">J. C. Dutta, S. Sharma, and R. Borgohain " Mixed Domain Modelling and Simulation of nano size ISFET for Bio-electronic device" </span><span lang="en-US"><em>Proc. of ICRTNT-06, Jadavpur University, India, 7th -9th Dec. 2006.</em></span></p>
<p><span lang="en-US">A. B. Kalita, S. Sharma, and R. Borgohain "Conical MOSFET: A Novel Device Geometry for Surrounding Gate MOSFET" </span><span lang="en-US"><em>International Conference on Recent Trends on Nanoscience and Technology (ICRTNT 06), Jadavpur University,7th-9th Dec. 2006.</em></span></p>
<p>Ranjita Das, Rashi Borgohain, <em>et al</em>. "Study on sheet resistance variation in ZnO nanorod arrays upon exposure to LPG at room temperature."&nbsp;<em>Energy, Power and Environment: Towards Sustainable Growth (ICEPE), 2015 International Conference on</em>. IEEE, 2015.</p>
<p>Rashi Borgohain, Prabin Kumar Boruah, and Sunandan Baruah. "Heavy-metal ion sensor using chitosan capped ZnS quantum dots."&nbsp;<em>Sensors and Actuators B: Chemical</em>&nbsp;226 (2016): 534-539.</p>
<p>Rashi Borgohain and Sunandan Baruah. "Design and analysis of UV detector using ZnO nanorods on interdigitated electrodes."&nbsp;<em>ADBU Journal of Engineering Technology</em>&nbsp;4 (2016).</p>
<p>Rashi Borgohain, <em>et al.</em> "Detection of Zn<sup>2+</sup> ion with UV light activated ZnO nanorods."&nbsp;<em>ADBU Journal of Engineering Technology</em>&nbsp;5.1 (2016).</p>
<p>Rashi Borgohain, <em>7et al</em>., <span lang="en-US">&ldquo;NO</span><sub><span lang="en-US">2</span></sub><span lang="en-US"> sensing at room temperature using ZnO nanorods on graphene&rdquo;, </span><span lang="en-US"><em>International Conference on Advances in Nanotechnology (ICAN), 2017</em></span></p>
<p>Rashi Borgohain and Sunandan Baruah. "Development and Testing of ZnO Nanorods Based Biosensor on Model Gram-Positive and Gram-Negative Bacteria."&nbsp;<em>IEEE Sensors Journal</em>&nbsp;17.9 (2017): 2649-2653.</p>
<p><span lang="en-US">Rashi Borgohain, Reema Das, Biplob Mondal, Visittapong Yordsri, Chanchana Thanachayanont, and Sunandan Baruah. "ZnO/ZnS Core-Shell Nanostructures for Low-Concentration NO 2 Sensing at Room Temperature." </span><span lang="en-US"><em>IEEE Sensors Journal</em></span><span lang="en-US"> 18, no. 17 (2018): 7203-7208.</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Book Chapter publication</strong></span></p>
<p><span lang="en-US">Arnab Sarkar, Rashi Borgohain, Bikash Agarwal and Sunandan Baruah &ldquo;ZnO Nanostructures for Environmental Applications&rdquo; ZnO Nanostructures Advances in Research and Applications, 2020 Nova Science Publishers.</span></p>
<p lang="en-US">&nbsp;</p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Bijoy Goswami</strong></span></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">1. Prasenjit Saha, Bijoy Goswami, &ldquo;Simulation Modelling and Study on the Impacts of Substrate Concentration and Gate Work Function in MOSFET having Doped-Pocket Substrate&rdquo; 2018, </span><span lang="en-US"><em>International Conference on Computing, Power and Communication Technologies (GUCON).</em></span><span lang="en-US"> DOI: 10.1109/GUCON.2018.8675078, Publisher: IEEE</span></p>
<p><span lang="en-US">Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar &ldquo;Drain Doping Engineering and its influence on Device Output Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo; </span><span lang="en-US"><em>International Conference in Communication, Devices and Networking </em></span><span lang="en-US">(ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</span></p>
<p><span lang="en-US">Prasenjit Saha, Bijoy Goswami, &ldquo;Implementation of a Doped-Pocket Region in order to Enhance the Device Performance of MOSFET&rdquo; </span><span lang="en-US"><em>International Conference in Communication, Devices and Networking</em></span><span lang="en-US"> (ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</span></p>
<p><span lang="en-US">Disha Bhattacharjee, BijoyGoswami, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar &ldquo;Influence of Drain Doping Engineering in Triple Splitted-Drain TFET model</span><span lang="en-US"><em>&rdquo; International Conference on Recent innovations in Electrical, Electronics&amp;</em></span></p>
<p><span lang="en-US"><em>Communication Engineering</em></span><span lang="en-US"> -ICRIEECE, July 27th and 28th 2018, School of Electrical Engineering, Kalinga institute of Industrial Technology (KIIT), Bhubaneswar, India. Publisher: IEEE</span></p>
<p><span lang="en-US">Bijoy Goswami, Debadipta Basak, Ayan Bhattacharya, Koelgeet Kaur, Sutanni Bhowmick, Subir Kumar Sarkar &ldquo;Analytical Modeling and Simulation of Low Power Salient Source DoubleGate TFET&rdquo;, </span><span lang="en-US"><em>Devices for Integarted Circuits</em></span><span lang="en-US"> (DevIC 2019), Kalyani University, 23-24March,2019, Publisher: IEEE</span></p>
<p><span lang="en-US">Goutika Paul, Bijoy Goswami and Subir Kumar Sarkar, &ldquo;H-Shaped Double Gate Tunnel FET For Low Power Applications&rdquo; </span><span lang="en-US"><em>International Conference in Recent Trends on Electronics &amp;Computer Science</em></span><span lang="en-US">, (ICRTECS-2019) 18</span><sup><span lang="en-US">th</span></sup><span lang="en-US">-19th March 2019, National Institute of Technology Silchar</span></p>
<p><span lang="en-US">Mandira Biswas, Goutika Paul, Bijoy Goswami and Subir Kumar Sarkar, &ldquo;PPV Based BottomGate Organic Field Effect Transistor (BGOFET)&rdquo; </span><span lang="en-US"><em>International Conference in Recent Trendson Electronics &amp; Computer Science</em></span><span lang="en-US">, (ICRTECS-2019) 18th-19th March 2019, National Institute of Technology Silchar.</span></p>
<p><span lang="en-US">Bijoy Goswami , Debadipta Basak , Koelgeet Kaur , Ayan Bhattacharya , Sutanni Bhowmick and Subir Kumar Sarkar , &ldquo;Reduction of Ambipolar Conduction in Centrally Aligned PNPN-DG TFET&rdquo;, 16th </span><span lang="en-US"><em>International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology</em></span><span lang="en-US"> (ECTI-CON), 10-13 July 2019, Pattaya, Chonburi, Thailand, Publisher: IEEE , INSPEC Accession Number: 19261359 DOI: 10.1109/ECTI-CON47248.2019.8955417</span></p>
<p><span lang="en-US">Bijoy Goswami , Disha Bhattacharjee , Dinesh Kumar Dash , Ayan Bhattacharya and Subir Kumar Sarkar &ldquo;Analytical modelling and simulation of drain doping engineered splitted drain structured TFET and its improved performance in subduing ambipolar effect&rdquo; Published in: </span><span lang="en-US"><em>IET Circuits, Devices &amp; Systems</em></span><span lang="en-US"> ( Volume:13 , Issue: 6 , 9 2019 ), Page(s): 888 &ndash; 895, INSPEC Accession Number: 19010537,DOI: 10.1049/iet-cds.2018.5261, Publisher: IET</span></p>
<p><span lang="en-US">Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy Goswami &amp; Subir Kumar Sarkar, &rdquo;A Novel Approach for RFID Based Smart EVM System&rdquo;, 1ST </span><span lang="en-US"><em>International Conference on Advances in Electrical Control Signal Systems</em></span><span lang="en-US"> (AECSS-2019)&rdquo;, ITER, SOA, (D.U) from Nov 8-9, 2019, organized by Department of EEE, ITER.</span></p>
<p><span lang="en-US">Bijoy Goswami, Ayan Bhattacharya, Komal Kumari, Priyadarshini Das, Wasim Reja and Subir Kumar Sarkar, &ldquo;A Novel Double Source TFET for Low Power Application&rdquo; </span><span lang="en-US"><em>4th Conference on Industrial and Information Systems (ICIIS),</em></span><span lang="en-US"> 18-20 Dec. 2019, Kandy, Sri Lanka, Publisher: IEEE, INSPEC Accession Number: 19530452, DOI: 10.1109/ICIIS47346.2019.9063290, Print on Demand (PoD) ISSN: 2164-7011.</span></p>
<p><span lang="en-US">Mandira Biswas, Anup Dey, Bijoy Goswami, Poushali Nandi and Subir Kumar Sarkar, &ldquo;Fabrication of Pentacene based Organic Field-Effect Transistors and their device characterization&rdquo;, </span><span lang="en-US"><em>MEMs based Sensors and Smart Nanostructured Devices</em></span><span lang="en-US"> (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</span></p>
<p><span lang="en-US">Sharmistha Sheekanra, Bijoy Goswami, Abir Jana, Subir Kumar Sarkar and Kousik Naskar, &ldquo;An Insight into Quantization Effects in Ultra-Thin Tunnel Field Effect Transistor&rdquo;, </span><span lang="en-US"><em>MEMs based Sensors and Smart Nanostructured Devices </em></span><span lang="en-US">(MSSND -2019), 27</span><sup><span lang="en-US">th</span></sup><span lang="en-US">-28</span><sup><span lang="en-US">th</span></sup><span lang="en-US"> Dec, 2019, Jadavpur University.</span></p>
<p><span lang="en-US">Anup Dey, Poushali Nandi, Subhashis Roy, Bijoy Kantha, Sourav Guha, Bijoy Goswami and Kousik Naska,&rdquo; Influence of Pd Nanoparticle in Improving the Sensitivity of Solgel Grown Nanocrystalline N-Wo3 Thin Film Based Hydrogen Sensor&rdquo;, </span><span lang="en-US"><em>MEMs based Sensors and Smart Nanostructured Devices</em></span><span lang="en-US"> (MSSND -2019), 27th-28</span><sup><span lang="en-US">th</span></sup><span lang="en-US"> Dec, 2019, Jadavpur University.</span></p>
<p><a name="_Hlk44021430"></a> <span lang="en-US">Bikram Biswas, Anup Dey, Bijoy Goswami, Subhashis Roy, Sutannti Bhowmick and Abir Jana, &ldquo;IoT-Based Smart Heart-Health Monitoring System&rdquo;, </span><span lang="en-US"><em>MEMs based Sensors andSmart Nanostructured Devices</em></span><span lang="en-US"> (MSSND -2019), 27</span><sup><span lang="en-US">th</span></sup><span lang="en-US">-28</span><sup><span lang="en-US">th</span></sup><span lang="en-US"> Dec, 2019, Jadavpur.</span></p>
<p><span lang="en-US">Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami, Subir Kumar Sarkar &ldquo;Designing Memristor Based Timing Circuits and Performance Comparison with CMOS Counterparts&rdquo; in </span><span lang="en-US"><em>Fourth International Conference on Smart Trends for Computing &amp; Communications</em></span><span lang="en-US"> (SmartCom 2020), January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand.</span></p>
<p><span lang="en-US">Anup Dey, Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi, S. K. Sarkar &ldquo;Room temperature Pt modified WO3/p-Si Film Gas Sensor for Detection of Methanol&rdquo; in </span><span lang="en-US"><em>Fourth International Conference on Smart Trends for Computing &amp; Communications </em></span><span lang="en-US">(SmartCom 2020), January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand.</span></p>
<p><span lang="en-US">Bijoy Goswami, Kousik Naskar, Sharmistha Sheekanra, Anup Day, Abir Jana and Subir Kumar Sarkar&rdquo; The Hypothesis of Two-Sources on TFET&rsquo;s Ambipolar Current and its Quantum Confinement Effect&rdquo;, 2020 </span><span lang="en-US"><em>8th International Electrical Engineering Congress </em></span><span lang="en-US">(iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand, Publisher: IEEE, INSPEC Accession Number:19573284, DOI:10.1109/iEECON48109.2020.229576,</span></p>
<p><span lang="en-US">Bijoy Goswami, Lavlesh Kumar Barman, Abir Jana, Anup Day, Wasim Reja and Subir Kumar Sarkar, &ldquo;A Source Pocket Doping in PNPN-DG TFET to Preclude Ambipolar Current&ndash; Two-Dimensional Simulation&rdquo;, 2020 </span><span lang="en-US"><em>8th International Electrical Engineering Congress</em></span><span lang="en-US"> (iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand. Publisher: IEEE, INSPEC Accession Number: 19573250, DOI: 10.1109/iEECON48109.2020.229575</span></p>
<p><span lang="en-US">Dipanjan Sen, Bijoy Goswami, Anup Dey, Priyanka Saha and Subir Kumar Sarkar &ldquo;Impact of Self-Heating and Nano-Gap Filling Factor on AlGaAs/GaAs Junction-Less DG-MOSFET Based Biosensor for Early Stage Diagnostics&rdquo; 2020 </span><span lang="en-US"><em>IEEE Region 10 Symposium</em></span><span lang="en-US"> (TENSYMP), 5th -7th June 2020, Dhaka, Bangladesh.</span></p>
<p><span lang="en-US">Abhishek Roy, Bijoy Goswami, Ujjwal Dey, Debasish Gayen, Wasim Reja and Subir Kumar Sarkar, &ldquo;Impact of Trapezoidal Channel in Double-Gate Tunnel Field Effect Transistor on Ambipolar Conduction for Ultra Low-Power Application&rdquo; </span><span lang="en-US"><em>The 17th IEEE International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology</em></span><span lang="en-US"> (ECTI-CON 2020), June 24th-27th ,2020, Phuket, Thailand,(Accepted, to be presented)</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Book Chapter publication</strong></span></p>
<p><span lang="en-US">Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya and Subir Kumar Sarkar &ldquo;Drain Doping Engineering and its influence on Device Output Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo; Springer</span></p>
<p><span lang="en-US"> Nature Singapore Pte Ltd 2019, &ldquo;Advances in Communication, Devices and Networking&rdquo; DOI 10.1007/978-981-13-3450-4 ISBN 9789811334504 (online) 9789811334498 (print)</span></p>
<p><span lang="en-US">Prasenjit Saha and Bijoy Goswami, &ldquo;Implementation of a Doped-Pocket Region in order to Enhance the Device Performance of MOSFET&rdquo;, Springer Nature Singapore Pte Ltd 2019, &ldquo;Advances in Communication, Devices and Networking&rdquo; DOI 10.1007/978-981-13-3450-4, ISBN 9789811334504 (online), ISBN 9789811334498 (print)</span></p>
<p><span lang="en-US">3. Bijoy Goswami, Sutanni Bhowmick, Arindam Halder, Debadipta Basak, Goutika Paul and Subir Kumar Sarkar, &ldquo;Implementation of L-shaped Double Metal Dual Gate TFET towards Improved Performance Characteristics and Reduced Ambipolarity" Springer Nature Singapore Pte Ltd 2020, &ldquo;Lecture Notes in Networks and Systems&rdquo; DOI 10.1007/978-981-32-9453-0_3, ISBN 978-981-32-9452-3 (Print) , ISBN 978- 981-32-9453-0 (Online)</span></p>
<p lang="en-US">&nbsp;</p>
<p>&nbsp;</p>
<p><strong>Ankur Jyoti Sarmah</strong></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p lang="en-US"><span lang="en-US">Nusrath, K., Sarmah, A., and Singh, J., "Flight Path Reconstruction and Wind Estimation Using Flight Test Data from Crash Data Recorder (CDR)," </span><span lang="en-US"><em>SAE Technical Paper</em></span><span lang="en-US"> 2014-01-2168, 2014, </span><span lang="en-US">https://doi.org/10.4271/2014-01-2168</span><span lang="en-US">.</span></p>
<p>&ldquo;<span lang="en-US">Implantation of Energy Detection Spectrum Sensing Using USRP N210 and Gnu-Radio&rdquo;, </span><span lang="en-US"><em>International Conference on Recent Innovations in Electrical, Electronics, Computer and Mechanical Engineering</em></span><span lang="en-US">, Kolkata, March, 2016. ISBN:978-93-85973-70-3.</span></p>
<p>&nbsp;</p>
<p><span lang="en-US"><strong>Smita Sarma</strong></span></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p lang="en-US"><span lang="en-US">S. Sarma, F. Begum and A. Dandapat, &ldquo;Design of a 2.5 GS/s 6-bit 2.4 mW Flash ADC in 45-nm CMOS Technology,&rdquo; 2017 </span><span lang="en-US"><em>International Conference on Inventive Computation Technologies</em></span><span lang="en-US">, ICICT 2017.</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Niranjan Jyoti Borah</strong></span></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">NJ Borah, G Kumar, &ldquo;Performance Analysis of Sub-22nm Metal Gate Fully Depleted SOI MOSFET with High-K Gate Dielectric&rdquo;, </span><span lang="en-US"><em>International Journal of Global Technology Initiatives</em></span><span lang="en-US"> 3 (1), B161-B168.</span></p>
<p><span lang="en-US">Niranjan Jyoti Borah, &ldquo;Performance Analysis of DG FD Metal Gate SOI MOSFET with High-K Gate Dielectric&rdquo;, 4th International Conference on &ldquo;Emerging Trends in Engineering and Technology, 24-25 April 2015 by College of Engineering, Teerthanker Mahaveer University Moradabad</span></p>
<p lang="en-US">&nbsp;</p>
<p><span lang="en-US"><strong>Nishant Bharti</strong></span></p>
<p><span lang="en-US"><strong>Research paper publications </strong></span></p>
<p><span lang="en-US">Maheshkumar H Kolekar, Nishant Bharti, Priti N Patil, &ldquo;Detection of fence climbing using activity recognition by Support Vector Machine classifier&rdquo;, </span><span lang="en-US"><em>2016 IEEE Region 10 Conference</em></span><span lang="en-US"> (TENCON), 22-25 Nov. 2016, DOI: 10.1109/TENCON.2016.7848029., Singapore.</span></p>
<p><span lang="en-US">Shivam Dutta, D. Venkata Sai Joga Rao, S. Hemanth Kumar, Nishant Bharti &ldquo;An embedded system to display and control of air quality inside a public transport&rdquo; </span><span lang="en-US"><em>MEMs based Sensors and Smart Nanostructured Devices</em></span><span lang="en-US"> (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</span></p>
<p lang="en-US">&nbsp;</p>
<p lang="en-US">&nbsp;</p>
<p lang="en-US">&nbsp;</p>
<p lang="en-US">&nbsp;</p>
<p>&nbsp;</p> */}




        </MDBContainer>

      </div>
    )
  }
}
