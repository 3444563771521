import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardText, MDBBtn } from 'mdbreact';
import { Typography, Divider, Grid, withStyles } from '@material-ui/core'
import './css/homepagenews.css';
import { PostData } from '../../api/service';
import { lightGreen, lightBlue } from '@material-ui/core/colors';
import Snack from '../../containers/Snack';

const styles = theme => ({
    divGrid: {
        cursor: 'pointer',
        backgroundColor: 'white',
        '&:hover': {
            transform: `scale(1.05)`,
            backgroundColor: lightBlue[50]
        }
    },
    notice: {
        padding: '20px',
        border: 'solid thin #f2f2f2',
        borderLeft: 'solid 5px lightgrey'
    }
})



class HomePageNews extends Component {
    state = {
        news: [],
        isLoaded: false
    }

    componentDidMount() {
        // perform the api call

        let d = {
            type: 'notice'
        }

        PostData(`/getnoticesbytype`, d)
            .then((resp) => {

                this.setState({
                    news: resp,
                    isLoaded: true
                })

            })


    }
    render() {
        return (
            <div>
                <div style={{
                    border: 'solid thin lightgrey',
                    backgroundColor: '#f2f2f2',
                    height: '100vh'
                }}>
                    {this.state.isLoaded ? (<marquee direction="up" scrollAmount="2" style={{ height: '100vh' }}>
                        {this.state.news.map((el, index) => {
                            return (
                                
                                    <NewsCard
                                        key={index}
                                        {...el}
                                        {...this.props}
                                    />
                                
                            )
                        })}
                    </marquee>) : null}
                </div>

                <div style={{ textAlign: 'left' }}>
                    <Link to="/notifications">
                        <MDBBtn
                            size="sm"
                            color="primary"
                        >
                            See More News
                        </MDBBtn>
                    </Link>
                </div>

            </div>
        )
    }
}

export default withStyles(styles)(HomePageNews);

class NewsCard extends Component {

    state = {
        isDownloading: false
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();

        this.setState({
            isDownloading: false
        })


    }

    handleClick(data) {

        this.setState({
            isDownloading: true
        })

        PostData(`/getfiledatafordownload`, data)
            .then((resp) => {

                //console.log(resp)
                this.showFile(resp, 1)
            })

    }

    modify_name = (name) => {
        let r = name.split('_');
        let new_name = r[0];
          return new_name.slice(0, 9) + '..';
    }

    render() {
        let {classes} = this.props;


        let i = [];
        if (this.props.upload_info !== null) {
            if (Array.isArray(this.props.upload_info) && this.props.upload_info.length > 0) {
                i = this.props.upload_info.map((el1, index1) =>
                    <Grid key={index1} item xs={4} sm={4} lg={4} md={4}
                        align='center'
                        style={{ border: 'solid thin lightgrey' }}
                        className={classes.divGrid}
                    >
                        <a
                            // onClick={this.handleClick.bind(this, el1)}
                            target="__blank"
                            href={el1}
                            
                        >
                            <i className="fa fa-file-pdf" style={{ color: 'red' }}></i>
                            <br />
                            <Typography
                                variant="caption"
                            >
                                Attachment
                            </Typography>
                        </a>
                    </Grid>
                )
            }
        }
        return (

            < MDBRow >
                {this.state.isDownloading ? (<Snack open={true} message="Downloading . . . ." />) : null}
            
                <MDBCol lg={12} md={12} style={{ margin: '5px', color: 'black' }}>
                    <Typography
                        variant="overline"
                    >
                        {this.props.date_no}, {this.props.month}
                    </Typography>

                    <Typography
                        variant="body2"
                        gutterBottom
                    >
                        <b>{this.props.title}</b>
                    </Typography>

                    <Grid container spacing={16} >
                        {i}
                    </Grid>
                    <br />

                    <Divider />


                </MDBCol>

            </MDBRow >
        )

    }
}
