import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Button } from 'mdbreact';
import { PostData } from '../../api/service';
import { stateToHTML } from 'draft-js-export-html';

class RichText extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            component_id: this.props.component_id,
            component_value: '',
            isLoaded: false,
            isEditable: false
        }
        this.getBase64 = this.getBase64.bind(this)
    }


    componentDidMount() {
        // fetch if any value already exists in the component

        let d = {
            component_id: this.state.component_id
        }

        // post route

        PostData(`/admin/getcomponent`, d)
            .then((resp) => {
                if (Array.isArray(resp) && resp.length > 0) {
                    const sampleMarkup =
                        resp[0].component_value;

                    const blocksFromHTML = convertFromHTML(sampleMarkup);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                    );
                    this.setState({
                        editorState: EditorState.createWithContent(state),
                        component_value: resp[0].component_value,
                        isLoaded: true
                    })
                }
            })


    }


    updateToServer = () => {
        let d = {
            component_id: this.state.component_id,
            component_value: stateToHTML(this.state.editorState.getCurrentContent())
        }

        // put route

        PostData(`/admin/updatecomponent`, d)
            .then((resp) => {
                console.log(resp)
            })

    }



    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    getBase64(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject(error)
            };
        })


    }


    uploadCallback(file) {
        return new Promise(
            (resolve, reject) => {

                this.getBase64(file).then((r) => {
                    resolve({ data: { link: r } });
                })
            }
        );
    }

    onEditClick = () => {
        this.setState({
            isEditable: !this.state.isEditable
        })
    }
    render() {
        return (
            <div>

                {this.state.isEditable ? (<div>

                    <Editor
                        editorState={this.state.editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            image: { uploadCallback: this.uploadCallback.bind(this) }
                        }}
                    />


                    <Button
                        color="primary"
                        onClick={this.updateToServer}
                    >
                        Update
                </Button>

                </div>) : (
                        <div dangerouslySetInnerHTML={{ __html: this.state.component_value }}>
                        </div>
                    )}

                {this.props.isLoggedIn ? (<div align="right">

                    <Button
                        color="primary"
                        onClick={this.onEditClick}
                    >
                        {!this.state.isEditable ? (
                            <i className="fa fa-edit text-white"> Edit</i>
                        ) : 
                        (
                                <i className="fa fa-cancel text-white"> Close</i>
                        )}
                    </Button>

                </div>) : null}

            </div>
        );
    }
}

export default RichText;