import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { Container } from 'mdbreact';

const items = [
  {
    id: 0,
    src: require('./../assets/deptimages/1.png'),
  },
  {
    id: 1,
    src: require('./../assets/deptimages/2.png'),
  },
  {
    id: 2,
    src: require('./../assets/deptimages/3.png'),
  },
  {
    id: 3,
    src: require('./../assets/deptimages/4.png'),
  },
  {
    id: 4,
    src: require('./../assets/deptimages/5.png'),
  }
];

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}

        >
          <img
            src={item.src}
            style={{width: '100%'}}
            alt=""
          />
          <CarouselCaption className="text-danger" />
        </CarouselItem>
      );
    });

    return (
      <div>
        <style>
          {
            `.custom-tag {
                max-width: 100;
                
                background: black;
              }
              @media only screen and (max-width: 600px){
                .custom-tag {
                  height: 150px
                }
              }
              
              `
          }
        </style>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
        {this.props.bread !== undefined ? (<div style={{
          height: '10vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: 'white',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Container>
            {this.props.bread}
          </Container>
        </div>) : null}
      </div>
    );
  }
}

export default Example;