import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, Alert, Button, MDBBtn } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { PostData } from '../api/service';

const dept_lab = [
    {
        title: 'Well Done!',
        description: 'Hello this is a description of the news',
        date: '21st April, 2019'
    },
    {
        title: 'New News about labs',
        description: 'Hello this is a description of the news',
        date: '23rd April, 2019'
    }
]

export default class EventContainer extends Component {
    state = {
        events: [],
        isLoaded: false,
    }

    componentDidMount() {
        // fetch the event

        let d = {
            type: 'events',
            event_type: this.props.event_type
        }

        PostData(`/getnoticesbytype`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    events: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        return (
            <div>
                <Example bread={`Home / Events / ${this.props.event_type}`}/>
                <MDBContainer style={{ padding: '20px' }}>
                    <div>
                        <TitleStyle
                            title={this.props.event_type}
                        />
                        {this.state.isLoaded ? (
                            <div>
                                {this.state.events.map((el, index) =>

                                    <NewsCard
                                        key={index}
                                        {...el}
                                    />

                                )}
                            </div>
                        ) : null}

                    </div>

                </MDBContainer>
            </div>
        )
    }
}


class NewsCard extends Component {
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();




    }

    handleClick(data, index) {

        PostData(`/getfiledatafordownload`, data)
            .then((resp) => {

                //console.log(resp)
                this.showFile(resp, index)
            })

    }
    render() {
        return (
            <div>
                <Alert className="bg-white" style={{
                    boxShadow: '2px 5px 10px rgba(0,0,0,0.5) !important'
                }}>
                    <h4 className="alert-heading" style={{fontWeight: 700}}>{this.props.title}</h4>
                    {this.props.opening_date != this.props.closing_date && <h6 className="alert-heading">From: {this.props.opening_date} To: {this.props.closing_date}</h6>}
                    {this.props.opening_date == this.props.closing_date && <h6 className="alert-heading">On: {this.props.opening_date} </h6>}
                    
                    <p dangerouslySetInnerHTML={{ __html: this.props.description }} />
                    <hr />
                    <p className="mb-0">
                        {this.props.date}
                    </p>
                    {this.props.upload_info !== null ? (<div className="d-flex justify-content-end">
                        {Array.isArray(this.props.upload_info) && this.props.upload_info.length > 0 ? (
                            <div>
                                {this.props.upload_info.map((el1, index1) =>
                                    <MDBBtn
                                        size="sm"
                                        color="blue-grey"
                                        style={{ color: 'white' }}
                                        key={index1}
                                        // onClick={this.handleClick.bind(this, el1, 0)}
                                        target="__blank"
                                        href={el1}
                                    >
                                        <i className="fa fa-paperclip"></i> Attachment
                                        </MDBBtn>)}
                            </div>
                        ) : null}
                    </div>) : null}
                </Alert>
            </div>
        )
    }
}
