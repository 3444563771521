import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class Pso extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / PSO`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
        <TitleStyle
            title={`Program Specific Outcomes (PSO)`}
          />
          <ol className="list-unstyled">
            <li>
              1. Students will be able to demonstrate software skills with competence to work and deliver in industry or research.
                </li>
            <li>
              2. Students will be competent to design applications and automation by using modern engineering tools with multi-disciplinary concepts.
                </li>


          </ol>

          <br />

        


             
          




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Pso)