import React, { Component } from 'react'
import { Container, Card, CardBody, Button, MDBRow, MDBCol, CardImage } from 'mdbreact';

const useful = [
    {
        title: "Admission",
        description: "Visit Assam Engineering College for more infomration. Click the button below",
        link: "http://aec.ac.in/undergraduate/disciplines",
        image: "http://aec.ac.in/static/media/admission.b36cc0a4.jpg"
    },
    {
        title: "Library",
        description: "Assam Engineering College Library",
        link: "http://aec.ac.in/library",
        image: "http://aec.ac.in/static/media/library.db3c9564.jpg"
    },
    {
        title: "Computer Centre",
        description: "Central Computing Center",
        link: "http://aec.ac.in/campuslife",
        image: "http://aec.ac.in/static/media/ccc_new.810d396c.jpg"
    },
    {
        title: "Multi Desciplinary Centre",
        description: "MDC",
        link: "http://aec.ac.in/campuslife",
        image: "http://aec.ac.in/static/media/mdc.cbb79db0.jpg"
    },
    {
        title: "Hostels",
        description: "Hostels",
        link: "http://aec.ac.in/hostel",
        image: "http://aec.ac.in/static/media/hostel-life.bda16827.jpg"
    },
    {
        title: "Anti-Ragging",
        description: "AntiRagging",
        link: "http://aec.ac.in/static/media/anti_ragging.e067ba85.pdf",
        image: "http://aec.ac.in/static/media/antiragging.64f10dd6.jpg"
    },
    {
        title: "Bank",
        description: "State Bank of India",
        link: "http://aec.ac.in/campuslife",
        image: "http://aec.ac.in/static/media/bank-1.f4597501.jpg"
    },
    {
        title: "Post-Office",
        description: "Post Office",
        link: "http://aec.ac.in/campuslife",
        image: "http://aec.ac.in/static/media/post-office.f896e8b7.jpg"
    },
    {
        title: "Techno Fetival - Udbhavanam",
        description: "Udbhavanam",
        link: "http://aec.ac.in/campuslife",
        image: "http://aec.ac.in/static/media/udv_new.7e2b6543.jpg"
    },
    {
        title: "Cultural Fetival - Pyrokinesis",
        description: "Pyrokinesis",
        link: "http://www.pyrokinesis.in/",
        image: "http://aec.ac.in/static/media/pyro_new.8475b7cd.jpg"
    },
    {
        title: "Swayam",
        description: "Best-in-Class Instructors",
        link: "https://swayam.gov.in/",
        image: "https://storage.googleapis.com/swayam2_central/assets/img/swayam_images/Slider/instructor.png"
    }
]


export default class UsefulLinks extends Component {
    render() {
        return (
            <div>
                <Container>
                    <div>
                        <h3 style={{ textAlign: 'center', paddingTop: '5vh' }}>USEFUL LINKS</h3>
                    </div>
                    <hr></hr>
                    <br></br>

                    <MDBRow>



                        {useful.map((el, index) =>

                            <MDBCol key={index} style={{ marginBottom: '2vh' }} sm="12" lg="3" md="3" xs="12">
                                <a
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    href={el.link}>
                                    <Card style={{

                                        height: '50vh',
                                        width: '100%'
                                    }}>
                                        <CardImage
                                            src={el.image}
                                            style={{
                                                height: '30vh',
                                                width: '100%'
                                            }}
                                        />
                                        <CardBody>
                                            <h5>
                                                {el.title}
                                            </h5>
                                        </CardBody>
                                    </Card>
                                </a>

                            </MDBCol>

                        )}
                    </MDBRow>




                </Container>
            </div>
        )
    }
}
