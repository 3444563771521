import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBBtn, Button } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData } from '../api/service';


const tableData = [
  {
    name: 'Pranjal Saikia',
    award: 'Something',
    year: '2018'
  },
  {
    name: 'Pranjal Saikia',
    award: 'Something',
    year: '2019'
  },
  {
    name: 'Pranjal Saikia',
    award: 'Something',
    year: '2019'
  }
]

export default class ActivitiesContainer extends Component {
  state = {
    tableData: [],
    tableData1: [],
    isLoaded: false
  }
  componentDidMount() {
    GetData('/getawards')
      .then(resp => {

        if (Array.isArray(resp)) {
          this.setState({
            tableData: resp,
            isLoaded: true
          })
        }

      })
  }
  render() {
    return (
      <div>
        <Example bread={`Home / Activities`} />
        <MDBContainer style={{ padding: '20px' }}>
          <div  style={{overflowX: 'scroll'}}>
            <TitleStyle
              title={`Students Activities`}
            />
            <table class="table" width="100%" cellspacing="0" cellpadding="7">
              <tbody>
                <tr>
                  <td width="33%">
                    <p lang="en-US"><strong>Participants</strong></p>
                  </td>
                  <td width="31%">
                    <p lang="en-US"><strong>Paper Title</strong></p>
                  </td>
                  <td width="21%">
                    <p lang="en-US"><strong>Presented At </strong></p>
                  </td>
                  <td width="14%">
                    <p lang="en-US"><strong>Date </strong></p>
                  </td>
                </tr>
                

</tbody>
</table>

            {/* <SecondaryStyle
              title={`Faculty / Student`}
            /> */}

          </div>
          <div>

            {/* <TableStyle
              data={this.state.tableData}
            /> */}



          </div>



        </MDBContainer>
      </div>
    )
  }
}


const TableStyle = (props) => {
  return (
    <div>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            <th>#</th>
            <th>Faculty/Student</th>
            <th>Name</th>
            <th>Award</th>
            <th>Year</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {props.data.map((el, index) =>
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{el.type}</td>
              <td>{el.name}</td>
              <td>{el.award}</td>
              <td>{el.year}</td>
            </tr>
          )}
        </MDBTableBody>
      </MDBTable>

      <div align="right">
        <Button
          size="sm"
          color="primary"

        >
          Click to get More
              </Button>
      </div>
    </div>
  )
}