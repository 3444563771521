import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class FacultyPublications extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Publications`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
         

        

        <h2 class="western"><strong>SCI/ SCIE/ ESCI/ Reference Journals: </strong></h2>
<ol>
<li>Dipanjan Sen, Arpan De, Bijoy Goswami, Sharmistha Shee, Subir Kumar Sarkar, &ldquo;Noise Immune Dielectric Modulated Dual Trench Transparent Gate Engineered MOSFET as a Label Free Biosensor: Proposal and Investigation&rdquo;, Journal of Computational Electronics 20, 2594&ndash;2603 (30 September 2021). https://doi.org/10.1007/s10825-021-01780-x, Impact Factor:<strong> 1.983 </strong></li>
<li>Lakhyadeep Konwar, Anjan Talukdar, Kandarpa Sarma, Navajit Saikia, Subhash Rajbangshi, &ldquo;Segmentation and Selective Feature Extraction for Human Detection to the Direction of Action Recognition&rdquo;, International Journal of Circuits, Systems, and Signal Processing, 15:1371-1386, September 2021. DOI: 10.46300/9106.2021.15.147.</li>
<li>Savio Jay Sengupta, Bijoy Goswami, Pritam Das, Subir Kumar Sarkar, &ldquo;A Noise Immune Double Suspended Gate MOSFET for Ultra-Low-Power Applications&rdquo;, Silicon<strong> 14</strong>,&nbsp;pages 5091&ndash;5101 (2022). https://doi.org/10.1007/s12633-021-01283-1. Impact Factor:<strong> 2.941 </strong></li>
<li>Swarnav Mukhopadhyay, Dipanjan Sen, Bijoy Goswami, Subir Kumar Sarkar, &ldquo;Performance Evaluation of Dielectrically Modulated Extended Gate Single cavity InGaAs/Si HTFET based Label-free Biosensor Considering Non-ideal Issues&rdquo;, IEEE Sensors Journal (Volume: 21, Issue: 4, Feb.15, 15 2021) Page(s): 4739 &ndash; 4746 DOI: 10.1109/JSEN.2020.3033576, Impact Factor:<strong> 4.325</strong></li>
<li>Simantika Choudhury, Nishant Bharti, Navajit Saikia, and Subhash Chandra Rajbongshi, "Detection of One-horned Rhino from Green Environment Background using Deep Learning&rdquo;, Journal of Green Engineering, vol. 10, pages 4657-4678, September 2020.</li>
<li>Gunajit Kalita, Navajit Saikia, and Amit Sravan Bora, &ldquo;Design of Reversible Decoder with minimum Garbage Output&rdquo;, International Journal of Advanced Trends in Computer Science and Engineering 9(3):3463-3470, June, 2020. DOI: 10.30534/ijatcse/2020/150932020.</li>
<li>Gautam Chakraborty, and Navajit Saikia, &ldquo;Speech Recognition of Isolated Words using a New Speech Database in Sylheti&rdquo;, International Journal of Recent Technology and Engineering (IJRTE), Volume-8 Issue-3, September 2019, pp. 6259-6268.</li>
<li>Disha Bhattacharjee , Bijoy Goswami ,Dinesh Kumar Dash , Ayan Bhattacharya and Subir Kumar Sarkar, &ldquo;Analytical modelling and simulation of drain doping engineered splitted drain structured TFET and its improved performance in subduing ambipolar effect&rdquo;, IET Circuits, Devices &amp; Systems ( Volume: 13 , Issue: 6 , 9 2019 ), Page(s): 888 &ndash; 895, DOI: 10.1049/iet-cds.2018.5261, Impact Factor:<strong> 1.53</strong></li>
<li>Amlan Jyoti Das and Navajit Saikia, &ldquo;Design of pedestrian detectors using combinations of scale spaces and classifiers&rdquo;, in Journal of King Saud University - Computer and Information Sciences (JKSUCI 2019), July, 2019, doi: https://doi.org/10.1016/j.jksuci.2019.06.017</li>
<li>Rashi Borgohain, Reema Das, Biplob Mondal, Visittapong Yordsri, Chanchana Thanachayanont, and Sunandan Baruah, "ZnO/ZnS Core-Shell Nanostructures for Low-Concentration NO 2 Sensing at Room Temperature", IEEE Sensors Journal 18, no. 17 (2018): 7203-7208. Impact Factor:<strong> 3.076</strong></li>
<li>Rashi Borgohain and Sunandan Baruah, "Development and Testing of ZnO Nanorods Based Biosensor on Model Gram-Positive and Gram-Negative Bacteria" IEEE Sensors Journal 17.9 (2017): 2649-2653. Impact Factor:<strong> 2.617</strong></li>
<li>Gunajit Kalita and Navajit Saikia, &ldquo;Designing reversible arithmetic, logic circuit to implement micro-operation in quantum computation&rdquo;, in Journal of Physics: Conference Series, vol. 759, no. 1, 2016, DOI: 10.1088/1742-6596/759/1/012097.</li>
<li>Rashi Borgohain, Prabin Kumar Boruah, and Sunandan Baruah. "Heavy-metal ion sensor using chitosan capped ZnS quantum dots", Sensors and Actuators B: Chemical 226 (2016): 534-539. Impact Factor: <strong>5.70</strong></li>
<li>Rashi Borgohain, et al. "Detection of Zn2+ ion with UV light activated ZnO nanorods." ADBU Journal of Engineering Technology 5.1 (2016).</li>
<li>Rashi Borgohain and Sunandan Baruah, "Design and analysis of UV detector using ZnO nanorods on interdigitated electrodes" ADBU Journal of Engineering Technology 4 (2016).</li>
<li>Navajit Saikia and Prabin K. Bora, &ldquo;Perceptual hash function for scalable video&rdquo;, International Journal of Information Security, vol. 13, no.1, Feb 2014, DOI: 0.1007/s10207-013-0211-z.</li>
<li>D. Hazarika and D.S. Pegu, &ldquo;Micro-controller based air pressure monitoring instrumentation system using optical fiber as sensor&rdquo;, <em>Optical Fiber Technology 19 (2013)83-87. </em>https://doi.org/10.1016/j.yofte.2012.11.006</li>
</ol>
<h2 class="western"><strong>Other Journal </strong></h2>
<ol>
<li>
<p>Ankur Jyoti Sarmah, Gaurab Narayan Dutta, Dakshee Lahkar, Neelotpal Talukdar, Bimrisha Lahkar, &ldquo;Biometric Authentication- Person Identification using Iris Recognition&rdquo;, International Journal of Innovative Science and Research Technology. Article Digital No: IJISRT22MAY1505, Volume 7, Issue 5, May,2022. DOI: https://doi.org/10.5281/zenodo.6658785</p>
</li>
<li>
<p>Ankur Jyoti Sarmah, Bhaskar Borah, Anirban Saikia, Madhusmita Nath, &ldquo;Animal Detection Using Instance Segmentation&rdquo;, Dickensian Journal, ISSN: 0012 2440, DOI: 10. 12001. DK. J2022.V22. I6.430, Volume 22, Issue 6, June 2022.</p>
</li>
<li>
<p>Niranjan Jyoti Borah, G Kumar, &ldquo;Performance Analysis of Sub-22nm Metal Gate Fully Depleted SOI MOSFET with High-K Gate Dielectric&rdquo;, International Journal of Global Technology Initiatives 3 (1)(2014), B161-B168</p>
</li>
</ol>

<h2 class="western"><strong>BOOKS/CHAPTERS</strong></h2>

<ol>
<li>
<p>Dipanjan Sen, Bijoy Goswami, Anup Dey and Subir Kumar Sarkar &ldquo;Performance Evaluation of Novel Channel Engineered Junctionless Double Gate MOSFET for Radiation Sensing and Low Power Circuit Application&rdquo; &ldquo;Electrical and Electronic Devices, Circuits and Materials: Design and Applications 2020&rdquo; CRC Taylor &amp;amp; Francis, DOI: https://doi.org/10.1201/97810030977, January 2021.</p>
</li>
<li>
<p>Anup Dey, Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi and S. K. Sarkar &ldquo;Room temperature Pt modified WO3/p-Si Film Gas Sensor for Detection of Methanol&rdquo; Springer Nature Singapore Pte Ltd 2020, Smart Trends in Computing and Communications: Proceedings of SmartCom 2020. DOI: 10.1007/978-981-15-5224-3_19, January 2021</p>
</li>
<li>
<p>Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami, Subir Kumar Sarkar, &ldquo;Designing Memristor Based Timing Circuits and Performance Comparison with CMOS Counterparts&rdquo; Springer Nature Singapore Pte Ltd 2020, Smart Trends in Computing and Communications: Proceedings of SmartCom 2020, DOI: 10.1007/978-981-15-5224-3_26, January 2021</p>
</li>
<li>
<p>Arnab Sarkar, Rashi Borgohain, Bikash Agarwal and Sunandan Baruah, &ldquo;ZnO Nanostructures for Environmental Applications&rdquo; , ZnO Nanostructures Advances in Research and Applications, 2020 Nova Science Publishers.</p>
</li>
<li>
<p>Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy Goswami &amp; Subir Kumar Sarkar, &ldquo;A Novel Approach for RFID Based Smart EVM System&rdquo;, Springer Nature Singapore Pte Ltd. 2020, Lecture Note Electrical Engineering, DOI: 10.1007/978-981-15-5262-5_49, July 2020</p>
</li>
<li>
<p>Bijoy Goswami, Suttanni Bhowmick, Arindam Haldar, Goutika Paul, Debodipta Basak and Subir Kumar Sarkar,&rdquo; Implementaion of L shaped dielectric double metal dual gate TFET towards improved performance characterized and reduced ambipolarity&rdquo; 2nd National Conference IPC 2019 at B.P. Poddar Institute of Management and Technology Kolkata West Bengal, 1-3rd February 2019. Published as book chapter in Networks and systems, in Information, Photonics and Communication in Lecture Notes , pp-21-29, Vol.79, 978-981-32-9453-0, DOI: 10.1007/978-981-32-9453-0_3, Jan 2020</p>
</li>
<li>
<p>Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya, and Subir Kumar Sarkar, &ldquo;Drain Doping Engineering and its influence on Device Output Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo;, Springer Nature Singapore Pte Ltd 2019, &ldquo;Advances in Communication, Devices and Networking&rdquo; DOI 10.1007/978-981-13-3450-4 ISBN 9789811334504 (online) 9789811334498 (print), January 2019</p>
</li>
<li>
<p>Amlan J. Das, Navajit Saikia, Kandarpa K. Sarma, &ldquo;Object classification and tracking in real-time&rdquo;, An overview in Emerging Technologies in Intelligent Applications for Image and Video Processing, Chapter-11, pp. 250-295,IGI Global, USA, 2016.</p>
</li>
</ol>
<h2 class="western"><strong>International Conference </strong></h2>
<ol>
<li>
<p>Rajarshi Das, Roshan Kumar Ojha, Dipam Tamuli, Swapnil Bhattacharjee and Niranjan Jyoti Borah, &ldquo;Hand Gesture-Based Recognition System for Human-Computer Interaction&rdquo;, International Conference on Machine Vision &amp; Augmented Intelligence (MAI 2022) held on 04-07, March 2022 hosted by NIT Jamshedpur.</p>
</li>
<li>
<p>Simantika Choudhury, Navajit Saikia, Subhash Chandra Rajbongshi and Abhishek Das, &ldquo;Employing generative adversarial network in low light animal detection&rdquo;, 4th International Conference on Communication and Computational Technologies (ICCCT 2022). Date: 26-27 February, 2022, Proceedings to be published in Springer Book Series 'Algorithms and Intelligent Systems'.</p>
</li>
<li>
<p>Amlan Jyoti Das, Navajit Saikia, Simantika Choudhury, &ldquo;Cascade-Based Pedestrian Detector Using Edge and Pattern Features, in&rdquo; Emerging Technologies for Smart Cities. Lecture Notes in Electrical Engineering, vol 765. Springer, Singapore, 2021.</p>
</li>
<li>
<p>Ursula Das, Neha Spriha Baruah, Bishal Das, Rashi Borgohain, &ldquo;Fruit Disease Detection using Image Processing Techniques&rdquo;, published in the proceedings of the AICTE sponsored International Conference on Advances in Computer Engineering &amp; Communication Technology (ICACET-2021) held during 22nd -23rd October 2021 .</p>
</li>
<li>
<p>Bijoy Goswami, Savio Jay Sengupta, Wasim Reja, Pritam Das, Subir Kumar Sarkar, &ldquo;Validation of Input/Output characteristics of Symmetrical Double Source TFET device&rdquo;, 2021 Devices for Integrated Circuit (DevIC), 19-20 May 2021, Kalyani, India, Publisher: IEEE, DOI: 10.1109/DevIC50843.2021.9455764</p>
</li>
<li>
<p>Bijoy Goswami, Pritam Das, Abhishek Dey, Savio Jay Sengupta, Subir Kumar Sarkar, &ldquo;Trapezoidal Channel Double Gate Tunnel FET Suitable for better Scalability, Speed and Low Power Application&rdquo;, 2021 Devices for Integrated Circuit (DevIC) , 19-20 May 2021, , Kalyani, India , Publisher: IEEE, DOI: 10.1109/DevIC50843.2021.9455811</p>
</li>
<li>
<p>Simantika Choudhury, Nishant Bharti, Navajit Saikia, and Subhash Chandra Rajbongshi, &ldquo;Automatic detection of Greater One-horned Rhinos by Using Deep Learning Models&rdquo;, in 6th International Online Conference on Recent Advancements in Interdisciplinary Research (ICRAIR), AIT, Conference Center, Thailand, 26-27 September 2020.</p>
</li>
<li>
<p>Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia, Kandarpa Kumar Sarma, and N.E. Mastorakis,&rdquo; Speech Database in Sylheti and Speech Recognition using Convolutional Neural Network", in 24th International Conference on Circuits, Systems, Communication and Computers, Platanias, Chania Crete Island, Greece, July 19-22, 2020.</p>
</li>
<li>
<p>Abhishek Roy, Bijoy Goswami, Ujjwal Dey, Debasish Gayen, Wasim Reja and Subir Kumar Sarkar, &ldquo;Impact of Trapezoidal Channel in Double-Gate Tunnel Field Effect Transistor on Ambipolar Conduction for Ultra Low-Power Application&rdquo;, The 17th IEEE International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology (ECTI-CON 2020), 24-27 June 2020, Phuket, Thailand, Publisher: IEEE ,DOI: 10.1109/ECTI-CON49241.2020.9158233</p>
</li>
<li>
<p>Dipanjan Sen, Bijoy Goswami, Anup Dey, Priyanka Saha and Subir Kumar Sarkar, &rdquo;Impact of Self-Heating and Nano-Gap Filling Factor on AlGaAs/GaAs Junction-Less DG-MOSFET Based Biosensor for Early Stage Diagnostics&rdquo;, 2020 IEEE Region 10 Page 107, Symposium (TENSYMP), 5th -7th June 2020, Dhaka, Bangladesh , Publisher: IEEE, DOI: 10.1109/TENSYMP50017.2020.9230864</p>
</li>
<li>
<p>Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami, Subir Kumar Sarkar, &ldquo;Designing Memristor Based Timing Circuits and Performance Comparison with CMOS Counterparts&rdquo;, in Fourth International Conference on Smart Trends for Computing &amp; Communications (SmartCom 2020), January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand, Publisher: Springer, Singapore DOI:10.1007/978-981-15-5224-3_26</p>
</li>
<li>
<p>Anup Dey, Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi, S. K. Sarkar, &ldquo;Room temperature Pt modified WO3/p-Si Film Gas Sensor for Detection of Methanol&rdquo;, in Fourth International Conference on Smart Trends for Computing &amp; Communications (SmartCom 2020), January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand, Publisher: Springer, Singapore DOI: 10.1007/978-981-15-5224-3_19</p>
</li>
<li>
<p>Bijoy Goswami, Kousik Naskar, Sharmistha Shee kanra, Anup Day, Abir Jana and Subir Kumar Sarkar, &ldquo;The Hypothesis of Two-Sources on TFET&lsquo;s Ambipolar Current and its Quantum Confinement Effect&rdquo; , 2020 8th International Electrical Engineering Congress (iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand, Publisher: IEEE , DOI:10.1109/iEECON48109.2020.229576</p>
</li>
<li>
<p>Bijoy Goswami, Lavlesh Kumar Barman , Abir Jana , Anup Day , Wasim Reja and Subir Kumar Sarkar, &ldquo;A Source Pocket Doping in PNPN-DG TFET to Preclude Ambipolar Current&ndash; Two Dimensional Simulation&rdquo; , 2020 8th International Electrical Engineering Congress (iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand. Publisher: IEEE, DOI: 10.1109/iEECON48109.2020.229575</p>
</li>
<li>
<p>Bijoy Goswami, Ayan Bhattacharya, Komal Kumari , Priyadarshini Das , Wasim Reja and Subir Kumar Sarkar, &ldquo;A Novel Double Source TFET for Low Power Application&rdquo;, 14th Conference on Industrial and Information Systems (ICIIS), 18-20 Dec. 2019, Kandy, Sri Lanka, Publisher: IEEE , DOI: 10.1109/ICIIS47346.2019.9063290, Print on Demand(PoD) ISSN: 2164-7011.</p>
</li>
<li>
<p>Sharmistha Shee Kanrar, Bijoy Goswami, Abir Jana, Subir Kumar Sarkar, &ldquo;An Insight into Quantization Effects in Ultra-Thin Tunnel Field Effect Transistor&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
</li>
<li>
<p>Anup Dey, Subhashis Roy, Sourav Guha, and Bijoy Goswami, &ldquo;Influence of Pd Nanoparticle in Improving the Sensitivity Of Sol-Gel Grown Nanocrystalline N-Wo3 Thin Film Based Hydrogen Sensor&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
</li>
<li>
<p>Bikram Biswas, Anup Dey, Bijoy Goswami, Subhashis Roy, Sutannti Bhowmick and Abir Jana, &ldquo;IoT-Based Smart Heart-Health Monitoring System&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
</li>
<li>
<p>Bijoy Goswami , Debadipta Basak , Koelgeet Kaur , Ayan Bhattacharya , Sutanni Bhowmick and Subir Kumar Sarkar , &ldquo;Reduction of Ambipolar Conduction in Centrally Aligned PNPN-DG TFET&rdquo;, 16th International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology (ECTI-CON), 10-13 July 2019, Pattaya, Chonburi, Thailand, Publisher: IEEE , DOI: 10.1109/ECTI-CON47248.2019.8955417</p>
</li>
<li>
<p>Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy Goswami &amp; Subir Kumar Sarkar, &ldquo;A Novel Approach for RFID Based Smart EVM System&rdquo;, 1st International Conference On Advances In Electrical Control Signal Systems (AECSS-2019) , ITER, SOA, (d.u) Nov 8-9, 2019. DOIhttps://doi.org/10.1007/978-981-15-5262-5_49</p>
</li>
<li>
<p>Goutika Paul, Bijoy Goswami and Subir Kumar Sarkar , &ldquo;H-Shaped Double Gate Tunnel FET For Low Power Applications&rdquo;, International Conference In Recent Trends on Electronics &amp; Computer Science, (ICRTECS-2019) 18th-19th March 2019, NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR</p>
</li>
<li>
<p>Mandira Biswas, Goutika Paul, Bijoy Goswami, and Subir Kumar Sarkar, &ldquo;PPV Based Bottom Gate Organic Field Effect Transistor (BGOFET)&rdquo;, International Conference In Recent Trends on Electronics &amp; Computer Science, (ICRTECS-2019) 18th-19th March 2019, NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR.</p>
</li>
<li>
<p>Bijoy Goswami, Debadipta Basak, Ayan Bhattacharya, Koelgeet Kaur, Sutanni Bhowmick, Subir Kumar Sarkar, &ldquo;Analytical Modeling and Simulation of Low Power Salient Source Double Gate TFET&rdquo; , Devices for Integrated Circuits (DevIC 2019), Kalyani University, 23-24 March, 2019 , Publisher: IEEE, DOI: 10.1109/DEVIC.2019.8783677</p>
</li>
<li>
<p>Gautam Chakraborty and Navajit Saikia, &ldquo;A Survey on Automatic Speech Recognition with special reference to Sylheti Language&rdquo;, Proceedings of 5th International Conference on Computing for Sustainable Global Development, 14-16 March 2018, DOI: not available (yet to be uploaded to the digital library).</p>
</li>
<li>
<p>Prasenjit Saha, Bijoy Goswami, &ldquo;Simulation Modelling and Study on the Impacts of Substrate Concentration and Gate Work Function in MOSFET having Doped-Pocket Substrate&rdquo;, 2018 International Conference on Computing, Power and Communication Technologies (GUCON). DOI: 10.1109/GUCON.2018.8675078, Publisher: IEEE</p>
</li>
<li>
<p>Disha Bhattacharjee, Bijoy Goswami, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar, &ldquo;Influence of Drain Doping Engineering in Triple Splitted-Drain TFET model‖ International Conference on Recent Innovations in Electrical, Electronics &amp; Communication Engineering -ICRIEECE&rdquo;, July 27th and 28th 2018, School of Electrical Engineering, Kalenga institute of Industrial Technology (KIIT), Bhubaneswar, India. Publisher: IEEE</p>
</li>
<li>
<p>Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar, &ldquo;Drain Doping Engineering and its influence on Device Output, Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo;, International Conference in Communication, Devices and Networking (ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</p>
</li>
<li>
<p>Prasenjit Saha, Bijoy Goswami, &ldquo;Implementation of a Doped-Pocket Region in order to Enhance the Device Performance of MOSFET&rdquo;, International Conference in Communication, Devices and Networking (ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</p>
</li>
<li>
<p>Bijoy Goswami, Disha Bhattachariee, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar, &ldquo;Demonstration of T-Shaped Channel Tunnel Field-Effect Transistors&rdquo;, 2018 2nd International Conference on Electronics, Materials Engineering &amp; Nano-Technology (IEMENTech), 4-5 May 2018, Publisher: IEEE, DOI: 10.1109/IEMENTECH.2018.8465213</p>
</li>
<li>
<p>Rashi Borgohain, 7et al., &ldquo;NO2 sensing at room temperature using ZnO nanorods on graphene&rdquo;, International Conference on Advances in Nanotechnology (ICAN), 2017</p>
</li>
<li>
<p>Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia and Kandarpa Kumar Sarma, &ldquo;Recurrent Neural Network Based Approach To Recognise Isolated Digits In Sylheti Language Using MFCC Features&rdquo;, in Proceedings of International Conference On Telecommunication, Power Analysis And Computing Techniques(ICTPACT) &ndash; 2017, ISBN: 978-1-5090-3381-2, 6th &ndash; 8th April 2017, DOI: not available (yet to be uploaded to digital library).</p>
</li>
<li>
<p>S. Sarma, F. Begum and A. Dandapat, &ldquo;Design of a 2.5 GS/s 6-bit 2.4 mW Flash ADC in 45-nm CMOS Technology&rdquo;, 2017 International Conference on Inventive Computation Technologies, ICICT 2017.</p>
</li>
<li>
<p>Maheshkumar H Kolekar, Nishant Bharti, Priti N Patil, &ldquo;Detection of fence climbing using activity recognition by Support Vector Machine classifier&rdquo;, 2016 IEEE Region 10 Conference (TENCON), 22-25 Nov. 2016, DOI: 10.1109/TENCON.2016.7848029., Singapore.</p>
</li>
<li>
<p>Amlan Jyoti Das and Navajit Saikia, &ldquo;Pedestrian Detection using Dense LDB descriptor combined with HOG&rdquo;, in International Conference on Information Technology (InCITe), Oct, 2016, DOI: 10.1109/INCITE.2016.7857635.</p>
</li>
<li>
<p>Gunajit Kalita and Navajit Saikia, &ldquo;Reversible Comparator Circuit Using a New Reversible Gate&rdquo;, in Proceedings of the Sixth International Conference on Computer and Communication Technology, 2015, pp. 419-423, 10.1145/2818567.2818685.</p>
</li>
<li>
<p>Navajit Saikia and Prabin K. Bora, &ldquo;Perceptual Hashing in the 3D-DWT Domain&rdquo;, in International Conference on Green Computing and Internet of Things (ICGCIoT), Oct, 2015, DOI: 10.1109/ICGCIoT.2015.7380552.</p>
</li>
<li>
<p>Ranjita Das, Rashi Borgohain, et al. "Study on sheet resistance variation in ZnO nanorod arrays upon exposure to LPG at room temperature", Energy, Power and Environment: Towards Sustainable Growth (ICEPE), 2015 International Conference on. IEEE, 2015.</p>
</li>
<li>
<p>Niranjan Jyoti Borah, &ldquo;Performance Analysis of DG FD Metal Gate SOI MOSFET with High-K Gate Dielectric&rdquo;, 4th International Conference on ―Emerging Trends in Engineering and Technology, 24-25 April 2015 by College of Engineering, Teerthanker Mahaveer University Moradabad</p>
</li>
<li>
<p>Navajit Saikia and Prabin K. Bora, &ldquo;Video Authentication Using Temporal Wavelet Transform&rdquo;, in 15th International Conference on Advanced Computing and Communications, Dec, 2007, DOI: 10.1109/ADCOM.2007.115</p>
</li>
<li>
<p>A. B. Kalita, S. Sharma, and R. Borgohain, "Conical MOSFET: A Novel Device Geometry for Surrounding Gate MOSFET" International Conference on Recent Trends on Nanoscience and Technology (ICRTNT 06), Jadavpur University,7th-9th Dec. 2006.</p>
</li>
<li>
<p>J. C. Dutta, S. Sharma, and R. Borgohain, "Mixed Domain Modelling and Simulation of nano size ISFET for Bio-electronic device", Proc. of ICRTNT-06, Jadavpur University, India, 7th -9th Dec. 2006.</p>
</li>
<li>
<p>Prabin K. Bora and Navajit Saikia, &ldquo;Retrieving Video in Wavelet Compressed Domain&rdquo;, in 8th International Conference on Information Technology, Dec, 2005, Bhubaneswar, DOI: not available.</p>
</li>
<li>
<p>Prabin K. Bora and Navajit Saikia, &ldquo;Image Retrieval Using One-Sided Linear Prediction Based Texture Modelling&rdquo;, in International Conference on Computer Vision, Graphics, and Image Processing, Dec, 2002, DOI: not available.</p>
</li>
</ol>
<h2 class="western"><strong>National Conference </strong></h2>
<ol>
<li>
<p>Hardik Das, Shruti Goswami, Namrata Borah, Rashi Borgohain, &ldquo;IoT Based Water Supply Monitoring and Notification System&rdquo;, National Conference on Recent Trends in Electronics and Communication Engineering &ndash; 2020 on 14/08/2020</p>
</li>
<li>
<p>Ankur Jyoti Sarmah, Samujjal Das, Bhashyab Jyoti Deka, Himshikhar Gayan, Mrinmoy Kalita, &ldquo;Implementation of smart city aspects using IoT&rdquo;, National Conference on Recent Trends in Electronics and Communication Engineering, Nandyal, August, 2020. ISBN: 978-93-87113-22-0.</p>
</li>
<li>
<p>Shivam Dutta, D. Venkata Sai Joga Rao, S. Hemanth Kumar, Nishant Bharti, &ldquo;An embedded system to display and control of air quality inside a public transport&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
</li>
<li>
<p>Simantika Choudhury, Navajit Saikia and Amlan Jyoti Das, &ldquo;Recent Trends in Learning Based Techniques for Human and Animal Detection&rdquo;, in Joint National Conference on Emerging Computing Technologies &amp; its Applications (NCECTA), April, 2019, PSG College of Technology, Coimbatore, Tamil Nadu, India.</p>
</li>
<li>
<p>Ankur Jyoti Sarmah, Ruchira Mazumder, Sanjib Kalita,Bijoy Goswami, &ldquo;LIGHT: Beyond the Bulb&rdquo; TEQIP SYMPOSIUM TO CELEBRATE THE 2015 INTERNATION YEAR OF LIGHT, IIT, GUWAHATI, Guwahati, India.</p>
</li>
<li>
<p>Ranbir Das, Asmita Paul, Bijoy Goswami, &ldquo;Design and Development of a Portable ECG&rdquo; International Conference on Green Energy and Smart Material Through Science, Technology And Management (GESM14), Gauhati University 21st-23rd Jan,2014, Guwahati, India.</p>
</li>
<li>
<p>Bijoy Goswami, &ldquo;Frequency Filter and Detector Using VHDL&rdquo; on Analog /Mixed Signal Design at NIT, SILCHAR,16th -20th January ,2012 Silchar, India.</p>
</li>
<li>
<p>Navajit Saikia and Prabin K. Bora, &ldquo;Robust Video Hashing Using the 3D-DWT&rdquo;, in National Conference on Communications (NCC), Jan, 2011, DOI: 10.1109/NCC.2011.5734750.</p>
</li>
<li>
<p>Bijoy Goswami,&rdquo; Design of Viterbi Decoder for low power consumption&rdquo; New approaches of Basis Science Towards the Development of engineering and Technology at DBCET 12th -13th March 2010, Guwahati, India.</p>
</li>
<li>
<p>Navajit Saikia and Prabin K. Bora, &ldquo;A Hybrid Algorithm for Video Authentication&rdquo;, in National Conference on Communications, 2008, DOI: not available.</p>
</li>
<li>
<p>R. Borgohain, S. Sharma, and J. C. Dutta, "Modelling Cylindrical nano size ISFET for Biosensor applications", Proc. of CODEC-06, Kolkata University, India, Dec. 2006.</p>
</li>
</ol>

<h2 class="western"><strong>Books (Editor Book Chapter) </strong></h2>
<ol>
<li>Advances in Communication and Computing &ndash; Prabin Kumar Bora, S. R. Mahadeva Prasanna, Kandarpa Kumar Sarma, Navajit Saikia Editors &ndash; Lecture Notes in Electrical Engineering &ndash; vol. 347, ISSN: 1876-1100, ISSN: 1876-1119 (electronic), ISBN: 978-81-322-2463-1, ISBN: 978-81-322-2464(eBook), DOI: 10.1007/978-81-322-2462-8, Springer-2015 .</li>
</ol>


             



{/* <p><strong><span style={{fontSize: 19}}>Journals</span></strong></p>
<p><br/></p>
<ol>
    <li>
        <p>Dipanjan Sen , Arpan De , Bijoy Goswami, Sharmistha Shee, Subir Kumar Sarkar &ldquo;Noise Immune Dielectric Modulated Dual Trench Transparent Gate Engineered MOSFET as a Label Free Biosensor: Proposal and Investigation&rdquo; Journal of Computational Electronics 20, 2594&ndash;2603 (Sept,&nbsp;2021). <a href="https://doi.org/10.1007/s10825-021-01780-x.">https://doi.org/10.1007/s10825-021-01780-x.</a></p>
    </li>
    <li>
        <p>Lakhyadeep Konwar, Anjan Talukdar, Kandarpa Sarma, Navajit Saikia, Subhash Rajbangshi, &ldquo;Segmentation and Selective Feature Extraction for Human Detection to the Direction of Action Recognition&rdquo;, International Journal of Circuits, Systems and Signal Processing, 15:1371-1386, September, 2021. DOI: 10.46300/9106.2021.15.147.</p>
    </li>
    <li>
        <p>Savio Jay Sengupta, Bijoy Goswami, Pritam Das, Subir Kumar Sarkar , &ldquo;A Noise Immune Double Suspended Gate MOSFET for Ultra Low-Power Applications&rdquo; Silicon (Aug,&nbsp;2021).<a href="https://doi.org/10.1007/s12633-021-01283-1.">https://doi.org/10.1007/s12633-021-01283-1.</a></p>
    </li>
    <li>
        <p>Swarnav Mukhopadhyay, Dipanjan Sen, Bijoy Goswami, Subir Kumar Sarkar, &ldquo;Performance Evaluation of Dielectrically Modulated Extended Gate Single cavity InGaAs/Si HTFET based Label-free Biosensor Considering Non-ideal Issues&rdquo; IEEE Sensors Journal ( Volume: 21, Issue: 4, Feb.15, 15 2021) Page(s): 4739 &ndash; 4746 DOI: 10.1109/JSEN.2020.3033576</p>
    </li>
    <li>
        <p>Simantika Choudhury, Nishant Bharti, Navajit Saikia and Subhash Chandra Rajbongshi, &ldquo;Detection of One-horned Rhino from Green Environment Background using Deep Learning&rdquo;, Journal of Green Engineering, vol. 10, pages 4657-4678, September, 2020.</p>
    </li>
    <li>
        <p>Gunajit Kalita, Navajit Saikia and Amit Sravan Bora, &ldquo;Design of Reversible Decoder with minimum Garbage Output&rdquo;, International Journal of Advanced Trends in Computer Science and Engineering 9(3):3463-3470, June, 2020. DOI: 10.30534/ijatcse/2020/150932020.</p>
    </li>
    <li>
        <p>Gautam Chakraborty, and Navajit Saikia, &ldquo;Speech Recognition of Isolated Words using a New Speech Database in Sylheti&rdquo;, International Journal of Recent Technology and Engineering (IJRTE), Volume-8 Issue-3, September 2019, pp. 6259-6268.</p>
    </li>
    <li>
        <p>Disha Bhattacharjee , Bijoy Goswami ,Dinesh Kumar Dash , Ayan Bhattacharya and Subir Kumar Sarkar &ldquo;Analytical modelling and simulation of drain doping engineered splitted drain structured TFET and its improved performance in subduing ambipolar effect&rdquo; IET Circuits, Devices &amp; Systems ( Volume: 13 , Issue: 6 , 9 2019 ), Page(s): 888 &ndash; 895, DOI: 10.1049/iet-cds.2018.5261</p>
    </li>
    <li>
        <p>Amlan Jyoti Das and Navajit Saikia, &ldquo;Design of pedestrian detectors using combinations of scale spaces and classifiers&rdquo;, in Journal of King Saud University - Computer and Information Sciences (JKSUCI 2019), July, 2019, doi: <a href="https://doi.org/10.1016/j.jksuci.2019.06.017">https://doi.org/10.1016/j.jksuci.2019.06.017</a></p>
    </li>
    <li>
        <p>Rashi Borgohain, Reema Das, Biplob Mondal, Visittapong Yordsri, Chanchana Thanachayanont, and Sunandan Baruah. &quot;ZnO/ZnS Core-Shell Nanostructures for Low-Concentration NO 2 Sensing at Room Temperature.&quot; IEEE Sensors Journal 18, no. 17 (2018): 7203-7208.</p>
    </li>
    <li>
        <p>Rashi Borgohain and Sunandan Baruah. &quot;Development and Testing of ZnO Nanorods Based Biosensor on Model Gram-Positive and Gram-Negative Bacteria.&quot;&nbsp;IEEE Sensors Journal&nbsp;17.9 (2017): 2649-2653.</p>
    </li>
    <li>
        <p>Gunajit Kalita and Navajit Saikia, &ldquo;Designing reversible arithmetic, logic circuit to implement micro-operation in quantum computation&rdquo;, in Journal of Physics: Conference Series, vol. 759, no. 1, 2016, DOI: 10.1088/1742-6596/759/1/012097.</p>
    </li>
    <li>
        <p>Rashi Borgohain, Prabin Kumar Boruah, and Sunandan Baruah. &quot;Heavy-metal ion sensor using chitosan capped ZnS quantum dots.&quot;&nbsp;Sensors and Actuators B: Chemical&nbsp;226 (2016): 534-539.</p>
    </li>
    <li>
        <p>Rashi Borgohain, et al. &quot;Detection of Zn2+ ion with UV light activated ZnO nanorods.&quot;&nbsp;ADBU Journal of Engineering Technology&nbsp;5.1 (2016).</p>
    </li>
    <li>
        <p>Rashi Borgohain and Sunandan Baruah. &quot;Design and analysis of UV detector using ZnO nanorods on interdigitated electrodes.&quot;&nbsp;ADBU Journal of Engineering Technology&nbsp;4 (2016).</p>
    </li>
    <li>
        <p>Navajit Saikia and Prabin K. Bora, &ldquo;Perceptual hash function for scalable video&rdquo;, in International Journal of Information Security, vol. 13, no.1, Feb, 2014, DOI: 0.1007/s10207-013-0211-z.</p>
    </li>
    <li>
        <p>Niranjan&nbsp;Jyoti Borah, G Kumar, &ldquo;Performance Analysis of Sub-22nm Metal Gate Fully Depleted SOI MOSFET with High-K Gate Dielectric&rdquo;, International Journal of Global Technology Initiatives 3 (1)(2014), B161-B168.</p>
    </li>
</ol>
<p><br/></p>
<p><br/></p>
<p><strong><span style={{fontSize: 19}}>International Conference</span></strong></p>
<p><br/></p>
<ol>
    <li>
        <p>Simantika Choudhury, Navajit Saikia, Subhash Chandra Rajbongshi and Abhishek Das, &ldquo;Employing generative adversarial network in low light animal detection&rdquo;,&nbsp;4th International Conference on Communication and Computational Technologies (ICCCT 2022). Date: 26-27 February, 2022, Proceedings to be published in Springer Book Series &apos;Algorithms and Intelligent Systems&apos;.</p>
    </li>
    <li>
        <p>Amlan Jyoti Das, Navajit Saikia, Simantika Choudhury, &ldquo;Cascade-Based Pedestrian Detector Using Edge and Pattern Features&rdquo;, in: Bora P.K., Nandi S., Laskar S. (eds) Emerging Technologies for Smart Cities. Lecture Notes in Electrical Engineering, vol 765. Springer, Singapore, 2021.</p>
    </li>
    <li>
        <p>Ursula Das, Neha Spriha Baruah, Bishal Das, Rashi Borgohain, &ldquo;Fruit Disease Detection using Image Processing Techniques&rdquo; published in the proceedings of the AICTE sponsored International Conference on Advances in Computer Engineering &amp; Communication Technology (ICACET-2021) held during 22nd -23rd October 2021</p>
    </li>
    <li>
        <p>Simantika Choudhury, Nishant Bharti, Navajit Saikia and Subhash Chandra Rajbongshi, &ldquo;Automatic detection of Greater One-horned Rhinos by Using Deep Learning Models&rdquo;, in 6th International Online Conference on Recent Advancements in Interdisciplinary Research (ICRAIR),AIT, Conference Center, Thailand,&nbsp;26-27 September, 2020.</p>
    </li>
    <li>
        <p>Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia, Kandarpa Kumar Sarma and N.E. Mastorakis, &ldquo;Speech Database in Sylheti and Speech Recognition using Convolutional Neural Network&rdquo;, in 24th International Conference on Circuits, Systems, Communication and Computers, Platanias, Chania Crete Island, Greece, July 19-22, 2020.</p>
    </li>
    <li>
        <p>Abhishek Roy, Bijoy Goswami, Ujjwal Dey, Debasish Gayen, Wasim Reja and Subir Kumar Sarkar, &ldquo;Impact of Trapezoidal Channel in Double-Gate Tunnel Field Effect Transistor on Ambipolar Conduction for Ultra Low-Power Application&rdquo; The 17th IEEE International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology (ECTI-CON 2020), 24-27 June 2020, Phuket, Thailand, Publisher: IEEE ,DOI: 10.1109/ECTI-CON49241.2020.9158233</p>
    </li>
    <li>
        <p>Dipanjan Sen, Bijoy Goswami, Anup Dey, Priyanka Saha and Subir Kumar Sarkar &ldquo;Impact of Self-Heating and Nano-Gap Filling Factor on AlGaAs/GaAs Junction-Less DG-MOSFET Based Biosensor for Early Stage Diagnostics&rdquo; 2020 IEEE Region 10 Symposium (TENSYMP), 5th -7th June 2020, Dhaka, Bangladesh , Publisher: IEEE, DOI: 10.1109/TENSYMP50017.2020.9230864</p>
    </li>
    <li>
        <p>Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami, Subir Kumar Sarkar &ldquo;Designing Memristor Based Timing Circuits and Performance Comparison with CMOS Counterparts&rdquo; in Fourth International Conference on Smart Trends for Computing &amp; Communications (SmartCom 2020),January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand, Publisher: Springer, Singapore DOI:10.1007/978-981-15-5224-3_26</p>
    </li>
    <li>
        <p>Anup Dey , Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi, S. K. Sarkar &ldquo;Room temperature Pt modified WO3/p-Si Film Gas Sensor for Detection of Methanol&rdquo; in Fourth International Conference on Smart Trends for Computing &amp; Communications (SmartCom 2020),January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok, Thailand, Publisher: Springer, Singapore DOI: 10.1007/978-981-15-5224-3_19</p>
    </li>
    <li>
        <p>Bijoy Goswami, Kousik Naskar, Sharmistha Shee kanra, Anup Day, Abir Jana and Subir Kumar Sarkar &ldquo;The Hypothesis of Two-Sources on TFET&rsquo;s Ambipolar Current and its Quantum Confinement Effect&rdquo; , 2020 8th International Electrical Engineering Congress (iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand, Publisher: IEEE , DOI:10.1109/iEECON48109.2020.229576</p>
    </li>
    <li>
        <p>Bijoy Goswami, Lavlesh Kumar Barman , Abir Jana , Anup Day , Wasim Reja and Subir Kumar Sarkar, &ldquo;A Source Pocket Doping in PNPN-DG TFET to Preclude Ambipolar Current&ndash; Two Dimensional Simulation&rdquo; , 2020 8th International Electrical Engineering Congress (iEECON-2020) ,4-6 March 2020, Chiang Mai, Thailand. Publisher: IEEE, DOI: 10.1109/iEECON48109.2020.229575</p>
    </li>
    <li>
        <p>Bijoy Goswami , Ayan Bhattacharya , Komal Kumari , Priyadarshini Das , Wasim Reja and Subir Kumar Sarkar, &ldquo;A Novel Double Source TFET for Low Power Application&rdquo; 14th Conference on Industrial and Information Systems (ICIIS), 18-20 Dec. 2019, Kandy, Sri Lanka, Publisher: IEEE , DOI: 10.1109/ICIIS47346.2019.9063290, Print on Demand(PoD) ISSN: 2164-7011.</p>
    </li>
    <li>
        <p>Bijoy Goswami , Debadipta Basak , Koelgeet Kaur , Ayan Bhattacharya , Sutanni Bhowmick and Subir Kumar Sarkar , &ldquo;Reduction of Ambipolar Conduction in Centrally Aligned PNPN-DG TFET&rdquo;, 16th International Conference on Electrical Engineering/Electronics, Computer, Telecommunications and Information Technology (ECTI-CON), 10-13 July 2019, Pattaya, Chonburi, Thailand, Publisher: IEEE , DOI: 10.1109/ECTI-CON47248.2019.8955417</p>
    </li>
    <li>
        <p>Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy Goswami &amp; Subir Kumar Sarkar, &ldquo;A Novel Approach for RFID Based Smart EVM System&rdquo;, 1st International Conference On Advances In Electrical Control Signal Systems (AECSS-2019)&rdquo; , ITER, SOA, (d.u) Nov 8-9, 2019. DOIhttps://doi.org/10.1007/978-981-15-5262-5_49</p>
    </li>
    <li>
        <p>Goutika Paul, Bijoy Goswami and Subir Kumar Sarkar , &ldquo;H-Shaped Double Gate Tunnel FET For Low Power Applications&rdquo; International Conference In Recent Trends on Electronics &amp; Computer Science, (ICRTECS-2019) 18th-19th March 2019, NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR</p>
    </li>
    <li>
        <p>Mandira Biswas, Goutika Paul , Bijoy Goswami and Subir Kumar Sarkar , &ldquo;PPV Based Bottom Gate Organic Field Effect Transistor (BGOFET) &rdquo; International Conference In Recent Trends on Electronics &amp; Computer Science, (ICRTECS-2019) 18th-19th March 2019, NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR.</p>
    </li>
    <li>
        <p>Gautam Chakraborty and Navajit Saikia, &ldquo;A Survey on Automatic Speech Recognition with special reference to Sylheti Language&rdquo;, Proceedings of 5th International Conference on Computing for Sustainable Global Development, 14-16 March, 2018, DOI: not available (yet to be uploaded to digital library).</p>
    </li>
    <li>
        <p>Prasenjit Saha , Bijoy Goswami, &ldquo;Simulation Modelling and Study on the Impacts of Substrate Concentration and Gate Work Function in MOSFET having Doped-Pocket Substrate&rdquo; 2018 International Conference on Computing, Power and Communication Technologies (GUCON). DOI: 10.1109/GUCON.2018.8675078, Publisher: IEEE</p>
    </li>
    <li>
        <p>Disha Bhattacharjee, Bijoy Goswami, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar &ldquo;Influence of Drain Doping Engineering in Triple Splitted-Drain TFET model&rdquo; International Conference on Recent innovations in Electrical, Electronics &amp; Communication Engineering -ICRIEECE&rdquo;. July 27th and 28th 2018, School of Electrical Engineering, Kalenga institute of Industrial Technology (KIIT), Bhubaneswar, India. Publisher: IEEE</p>
    </li>
    <li>
        <p>Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar &ldquo;Drain Doping Engineering and its influence on Device Output Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo; International Conference in Communication, Devices and Networking (ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</p>
    </li>
    <li>
        <p>Prasenjit Saha ,Bijoy Goswami ,&ldquo;Implementation of a Doped-Pocket Region in order to Enhance the Device Performance of MOSFET&rdquo; International Conference in Communication, Devices and Networking (ICCDN 2018) Sikkim Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.</p>
    </li>
    <li>
        <p>Bijoy Goswami, Disha Bhattachariee, Dinesh Kumar Dash, Ayan Bhattacharya, Subir Kumar Sarkar &ldquo;Demonstration of T-Shaped Channel Tunnel Field-Effect Transistors&rdquo; 2018 2nd International Conference on Electronics, Materials Engineering &amp; Nano-Technology (IEMENTech), 4-5 May 2018, Publisher: IEEE, DOI: 10.1109/IEMENTECH.2018.8465213</p>
    </li>
    <li>
        <p>Rashi Borgohain, 7et al., &ldquo;NO2 sensing at room temperature using ZnO nanorods on graphene&rdquo;, International Conference on Advances in Nanotechnology (ICAN), 2017</p>
    </li>
    <li>
        <p>Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia and Kandarpa Kumar Sarma, &ldquo;Recurrent Neural Network Based Approach To Recognise Isolated Digits In Sylheti Language Using MFCC Features&rdquo;, in Proceedings of International Conference On Telecommunication, Power Analysis And Computing Techniques(ICTPACT) &ndash; 2017, ISBN: 978-1-5090-3381-2, 6th &ndash; 8th April 2017, DOI: not available (yet to be uploaded to digital library).</p>
    </li>
    <li>
        <p>S. Sarma, F. Begum and A. Dandapat, &ldquo;Design of a 2.5 GS/s 6-bit 2.4 mW Flash ADC in 45-nm CMOS Technology,&rdquo; 2017 International Conference on Inventive Computation Technologies, ICICT 2017.</p>
    </li>
    <li>
        <p>Maheshkumar H Kolekar, Nishant Bharti, Priti N Patil, &ldquo;Detection of fence climbing using activity recognition by Support Vector Machine classifier&rdquo;, 2016 IEEE Region 10 Conference (TENCON), 22-25 Nov. 2016, DOI: 10.1109/TENCON.2016.7848029., Singapore.</p>
    </li>
    <li>
        <p>Amlan Jyoti Das and Navajit Saikia, &ldquo;Pedestrian Detection using Dense LDB descriptor combined with HOG&rdquo;, in International Conference on Information Technology (InCITe), Oct, 2016, DOI: 10.1109/INCITE.2016.7857635.</p>
    </li>
    <li>
        <p>Gunajit Kalita and Navajit Saikia, &ldquo;Reversible Comparator Circuit Using a New Reversible Gate&rdquo;, in Proceedings of the Sixth International Conference on Computer and Communication Technology, 2015, pp. 419-423, 10.1145/2818567.2818685.</p>
    </li>
    <li>
        <p>Navajit Saikia and Prabin K. Bora, &ldquo;Perceptual Hashing in the 3D-DWT Domain&rdquo;, in International Conference on Green Computing and Internet of Things (ICGCIoT), Oct, 2015, DOI: 10.1109/ICGCIoT.2015.7380552.</p>
    </li>
    <li>
        <p>Ranjita Das, Rashi Borgohain, et al. &quot;Study on sheet resistance variation in ZnO nanorod arrays upon exposure to LPG at room temperature.&quot;&nbsp;Energy, Power and Environment: Towards Sustainable Growth (ICEPE), 2015 International Conference on. IEEE, 2015.</p>
    </li>
    <li>
        <p>Niranjan Jyoti Borah, &ldquo;Performance Analysis of DG FD Metal Gate SOI MOSFET with High-K Gate Dielectric&rdquo;, 4th International Conference on &ldquo;Emerging Trends in Engineering and Technology, 24-25 April 2015 by College of Engineering, Teerthanker Mahaveer University Moradabad</p>
    </li>
    <li>
        <p>Navajit Saikia and Prabin K. Bora, &ldquo;Video Authentication Using Temporal Wavelet Transform&rdquo;, in 15th International Conference on Advanced Computing and Communications, Dec, 2007, DOI: 10.1109/ADCOM.2007.115</p>
    </li>
    <li>
        <p>A. B. Kalita, S. Sharma, and R. Borgohain &quot;Conical MOSFET: A Novel Device Geometry for Surrounding Gate MOSFET&quot; International Conference on Recent Trends on Nanoscience and Technology (ICRTNT 06), Jadavpur University,7th-9th Dec. 2006.</p>
    </li>
    <li>
        <p>J. C. Dutta, S. Sharma, and R. Borgohain &quot; Mixed Domain Modelling and Simulation of nano size ISFET for Bio-electronic device&quot; Proc. of ICRTNT-06, Jadavpur University, India, 7th -9th Dec. 2006.</p>
    </li>
    <li>
        <p>Prabin K. Bora and Navajit Saikia, &ldquo;Retrieving Video in Wavelet Compressed Domain&rdquo;, in 8th International Conference on Information Technology, Dec, 2005, Bhubaneswar, DOI: not available.</p>
    </li>
    <li>
        <p>Prabin K. Bora and Navajit Saikia, &ldquo; Image Retrieval Using One-Sided Linear Prediction Based Texture Modelling&rdquo;, in International Conference on Computer Vision, Graphics, and Image Processing, Dec, 2002, DOI: not available.</p>
    </li>
</ol>
<p><br/></p>
<p><strong><span style={{fontSize: 19}}>National Conference</span></strong></p>
<p><br/></p>
<ol>
    <li>
        <p>Bijoy Goswami, Savio Jay Sengupta, Wasim Reja, Pritam Das, Subir Kumar Sarkar &ldquo;Validation of Input/Output characteristics of Symmetrical Double Source TFET device&rdquo; 2021 Devices for Integrated Circuit (DevIC), 19-20 May 2021, Kalyani, India, Publisher: IEEE, DOI: 10.1109/DevIC50843.2021.9455764</p>
    </li>
    <li>
        <p>Bijoy Goswami, Pritam Das, Abhishek Dey; Savio Jay Sengupta, Subir Kumar Sarkar &ldquo;Trapezoidal Channel Double Gate Tunnel FET Suitable for better Scalability, Speed and Low Power Application&rdquo; 2021 Devices for Integrated Circuit (DevIC) , 19-20 May 2021, , Kalyani, India , Publisher: IEEE, DOI: 10.1109/DevIC50843.2021.9455811</p>
    </li>
    <li>
        <p>Hardik Das, Shruti Goswami, Namrata Borah, Rashi Borgohain,&rdquo; IoT Based Water Supply Monitoring and Notification System&rdquo;, National Conference on Recent Trends in Electronics and Communication Engineering &ndash; 2020 on 14/08/2020</p>
    </li>
    <li>
        <p>Ankur Jyoti Sarmah, Samujjal Das, Bhashyab Jyoti Deka, Himshikhar Gayan, Mrinmoy Kalita, &ldquo;Implementation of smart city aspects using IoT&rdquo;, National Conference on Recent Trends in Electronics and Communication Engineering, Nandyal, August, 2020. ISBN: 978-93-87113-22-0.</p>
    </li>
    <li>
        <p>Sharmistha Shee Kanrar, Bijoy Goswami, Abir Jana, Subir Kumar Sarkar, &ldquo;An Insight into Quantization Effects in Ultra-Thin Tunnel Field Effect Transistor&rdquo; MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
    </li>
    <li>
        <p>Anup Dey, Subhashis Roy, Sourav Guha and Bijoy Goswami, &ldquo;Influence of Pd Nanoparticle in Improving the Sensitivity Of Sol-Gel Grown Nanocrystalline N-Wo3 Thin Film Based Hydrogen Sensor&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
    </li>
    <li>
        <p>Bikram Biswas, Anup Dey, Bijoy Goswami, Subhashis Roy, Sutannti Bhowmick and Abir Jana, &ldquo; IoT-Based Smart Heart-Health Monitoring System&rdquo;, MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
    </li>
    <li>
        <p>Shivam Dutta, D. Venkata Sai Joga Rao, S. Hemanth Kumar, Nishant Bharti &ldquo;An embedded system to display and control of air quality inside a public transport&rdquo; MEMs based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.</p>
    </li>
    <li>
        <p>Simantika Choudhury, Navajit Saikia and Amlan Jyoti Das, &ldquo;Recent Trends in Learning Based Techniques for Human and Animal Detection&rdquo; in Joint National Conference on Emerging Computing Technologies &amp; its Applications (NCECTA), April, 2019, PSG College of Technology, Coimbatore, Tamil Nadu, India.</p>
    </li>
    <li>
        <p>Bijoy Goswami, Debadipta Basak, Ayan Bhattacharya, Koelgeet Kaur, Sutanni Bhowmick, Subir Kumar Sarkar &ldquo;Analytical Modeling and Simulation of Low Power Salient Source Double Gate TFET&rdquo; , Devices for Integarted Circuits (DevIC 2019), Kalyani University, 23-24 March,2019 , Publisher: IEEE, DOI: 10.1109/DEVIC.2019.8783677</p>
    </li>
    <li>
        <p>Navajit Saikia and Prabin K. Bora, &ldquo;Robust Video Hashing Using the 3D-DWT&rdquo;, in National Conference on Communications (NCC), Jan, 2011, DOI: 10.1109/NCC.2011.5734750.</p>
    </li>
    <li>
        <p>Navajit Saikia and Prabin K. Bora, &ldquo;A Hybrid Algorithm for Video Authentication&rdquo;, in National Conference on Communications, 2008, DOI: not available.</p>
    </li>
    <li>
        <p>R. Borgohain, S. Sharma, and J. C. Dutta &quot;Modelling Cylindrical nano size ISFET for Biosensor applications&quot; Proc. of CODEC-06, Kolkata University, India, Dec. 2006.</p>
    </li>
</ol>
<p><br/></p>
<p><span style={{fontSize: 19}}><strong>Book Chapters</strong></span></p>
<p><br/></p>
<ol>
    <li>
        <p>Bijoy Goswami, Sutanni Bhowmick, Arindam Halder, Debadipta Basak, Goutika Paul and Subir Kumar Sarkar, &ldquo;Implementation of L-shaped Double Metal Dual Gate TFET towards Improved Performance Characteristics and Reduced Ambipolarity&quot; Springer Nature Singapore Pte Ltd 2020, &ldquo;Lecture Notes in Networks and Systems&rdquo; DOI 10.1007/978-981- 32-9453-0_3, ISBN 978-981-32-9452-3 (Print) , ISBN 978-981-32-9453-0 (Online)</p>
    </li>
    <li>
        <p>Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy Goswami &amp; Subir Kumar Sarkar &ldquo;A Novel Approach for RFID Based Smart EVM System&rdquo;, Springer Nature Singapore Pte Ltd. 2020, Lecture Note Electrical Engineering , DOI: 10.1007/978-981-15-5262-5_49</p>
    </li>
    <li>
        <p>Dipanjan Sen, Bijoy Goswami, Anup Dey and Subir Kumar Sarkar &ldquo;Performance Evaluation of Novel Channel Engineered Junctionless Double Gate MOSFET for Radiation Sensing and Low Power Circuit Application&rdquo; &ldquo;Electrical and Electronic Devices, Circuits and Materials: Design and Applications 2020&rdquo; CRC Taylor &amp;amp; Francis , DOI: <a href="https://doi.org/10.1201/97810030977">https://doi.org/10.1201/97810030977</a></p>
    </li>
    <li>
        <p>Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami, Subir Kumar Sarkar , &ldquo;Designing Memristor Based Timing Circuits and Performance Comparison with CMOS Counterparts&rdquo; Springer Nature Singapore Pte Ltd 2020, Smart Trends in Computing and Communications: Proceedings of SmartCom 2020 , DOI: 10.1007/978-981-15-5224-3_26</p>
    </li>
    <li>
        <p>Anup Dey , Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi and S. K. Sarkar &ldquo;Room temperature Pt modified WO3/p-Si Film Gas Sensor for Detection of Methanol&rdquo; Springer Nature Singapore Pte Ltd 2020, Smart Trends in Computing and Communications: Proceedings of SmartCom 2020. DOI: 10.1007/978-981-15-5224-3_19</p>
    </li>
    <li>
        <p>Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya and Subir Kumar Sarkar &ldquo;Drain Doping Engineering and its influence on Device Output Characteristics and Ambipolar Conduction on a Splitted-Drain TFET model&rdquo; Springer Nature Singapore Pte Ltd 2019, &ldquo;Advances in Communication, Devices and Networking&rdquo; DOI 10.1007/978-981-13-3450-4 ISBN 9789811334504 (online) 9789811334498 (print)</p>
    </li>
    <li>
        <p>Amlan J. Das, Navajit Saikia, Kandarpa K. Sarma,&nbsp;&ldquo;Object classification and tracking in real-time: An overview&rdquo;&nbsp;in&nbsp;Emerging Technologies in Intelligent Applications for Image and Video Processing, Chapter-11, pp. 250-295,IGI Global, USA, 2016.</p>
    </li>
</ol>
<p><br/></p>
<p><strong><span style={{fontSize: 19}}>Books (Editor Book Chapter)</span></strong></p>
<p><br/></p>
<ol>
    <li>
        <p>Advances in Communication and Computing &ndash; Prabin Kumar Bora, S. R. Mahadeva Prasanna, Kandarpa Kumar Sarma, Navajit Saikia Editors &ndash; Lecture Notes in Electrical Engineering &ndash; vol. 347, ISSN: 1876-1100, ISSN: 1876-1119 (electronic), ISBN: 978-81-322-2463-1, ISBN: 978-81-322-2464(eBook), DOI: 10.1007/978-81-322-2462-8, Springer-2015 .</p>
    </li>
</ol> */}



        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(FacultyPublications)
