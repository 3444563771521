export const HOD_LIST = [
    {
        name: "Prof. S. Bhattacharya",
        year: ""
    },
    {
        name: "Prof. P. Mahanta",
        year: ""
    },
    {
        name: "Prof. A. Nath",
        year: ""
    },
    {
        name: "Prof. A. Gogoi",
        year: ""
    },
    {
        name: "Prof. G. D. Krishna",
        year: ""
    },
    {
        name: "Prof. P. K. Brahma",
        year: ""
    },
    {
        name: "Assoc. Prof. D.S. Pegu",
        year: ""
    },
    {
        name: "Assoc. Prof. A.K. Kalita",
        year: ""
    },
    {
        name: "Assoc. Prof. D. S. Pegu",
        year: ""
    },
    {
        name: "Assoc. Prof. N. Saikia",
        year: ""
    },

]