import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Example from '../component/RecentNews';


export default class Library extends Component {
    render() {
        return (
            <div>

                <Example />
                <div className="container">
                    <h4 style={{ paddingTop: '5vh', textAlign: 'center', color:'#DC3545' }}><b>Bipin Chandra Baruah Library</b></h4>
                    <br></br>
                    {/* <div className="about-content">

<div><span>1978</span><br /> Established</div>
<div className="middle_div"><span>25,000+</span><br /> Books</div>
<div><span>350.00 m<sup>2</sup></span><br /> Carpet-area</div>

</div> */}
                    <MDBContainer>
                        <MDBRow>
                            <MDBCol size="4" textalign='center'>
                                <h4>1978</h4>
                                <p>Established</p>
                            </MDBCol>
                            <MDBCol size="4"><h4>25,000+</h4>
                                <p>BOOKS</p>
                            </MDBCol>
                            <MDBCol size="4">
                                <h4>350.00 msquare</h4>
                                <p>Carpet-area</p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>

                    <header><h4>About the library</h4></header>
                    {/* <p align="justify">
<b>Bipin Chandra Baruah Library</b></p> */}
                    <p align="justify">

                        The college library is named after the founder principal of the college Late Bipin Chandra Barua to remember his contribution towards the college. At present, the library has more than 25,000 books, course related, reference and others.
                        
                        The library also has adequate number of national newspapers, magazines and journals to cater to the needs of the students, teachers and research scholars.
                        
                        The library has a reading room to accommodate nearly 150 readers at a time. It has a Book Bank facility to help the needy meritorious students of the college.
                        
                        The library also has a photo-copier machine to help the readers. The final examination question papers of AHSEC & GU are also available at the library.
                        
                        Two new computers have been added with LAN facility to the college library to facilitate the students, teachers and other library users. With the purchase of new books, text books as well as reference books, the strength of the library has increased to over 25,000 books..
</p>

                
                      <MDBContainer>
                      <MDBRow class="w-100">
        <MDBCol size="4">
          <div>
            <div className="styled-title">

              <h5>
                <span>OUR</span>VISION
              </h5>

              <div></div>


            </div>
            <p>
            To develop as an excellent centre of knowledge, an essential component of the academic and creative life of the institution in fostering teaching, learning, research and societal issues for sustainable development.
             </p>

             <div>
            <div className="styled-title">
              <h5>
                <span>OUR</span>MISSION
              </h5>
              <div></div>

            </div>
            <p>
              <ol>
                <li>
                Collecting, preserving and providing access to high quality educational resources by utilising the state-of-the-art technologies
                </li>
                <li>
                Providing best innovative services
                </li>
                <li>
                Creating a very comfortable physical environment.
                </li>
              </ol>
            </p>
          </div>

          </div>
        </MDBCol>
        <MDBCol size="4">
          <div>
            <div className="styled-title">

              <h3>
                <span>NEWS</span>&amp; EVENTS
              </h3>

              <div></div>
            </div>
          </div>
        </MDBCol>
        <MDBCol size="4">
          <div>
            <div className="styled-title">

              <h4>
                <span>OPENNING</span>HOURS
                </h4>

              <div></div>
            </div>
            <br></br>

            <div>
            <div className="timing-icon">
                                <i className="fa fa-calendar fa-4x"></i>
                            </div>
                            <div className="timing-details">
                                <h4>Office Hours</h4>
                                <p>10.00 a.m. to 04.00 p.m.</p>
                                <p>6 days a week except the government holidays.</p>
                            </div>
                            <div>
                            <div className="timing-details">
                                <h4>Email</h4>
                                <p><a href="mailto:manikpradhani33@gmail.com">manikpradhani33@gmail.com</a></p>
                            </div>
                            </div>
            </div>


          </div>
        </MDBCol>
   
                        </MDBRow>
                    </MDBContainer>

                </div>
            </div>
        )
    }
}
