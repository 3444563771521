import React, { Component } from 'react';

const data = [
    {
        title: 'Home',
        path: '/',
        hasSubnav: false,
        subnav: []
    },
    {
        title: 'Academics',
        path: '/academics',
        hasSubnav: true,
        subnav: [
            {
                title: 'Schools',
                path: '/academic/schools',
                hasSubnav: true,
                subnav: [
                    {
                        title: 'School of engineering',
                        path: '/academic/schools/engineering',
                        hasSubnav: true,
                        subnav: [
                            {
                                title: 'Mechanical Engineering',
                                path: '/academic/schools/engineering/me',
                                hasSubnav: false,
                                subnav: []
                            }
                        ]
                    },
                    {
                        title: 'School of Science',
                        path: '/academic/schools/science',
                        hasSubnav: false,
                        subnav: []
                    },
                    {
                        title: 'School of Humanities',
                        path: '/academic/schools/humanities',
                        hasSubnav: true,
                        subnav: [
                            {
                                title: 'Humanitites Dept',
                                path: '/hum',
                                hasSubnav: false,
                                subnav: []
                            }
                        ]
                    }
                ]
            },
            {
                title: 'About',
                path: '/about',
                hasSubnav: false,
                subnav: []
            }
        ]
    }
]

class MultiNavBar extends Component {
    render() {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        backgroundColor: this.props.backgroundOfNav
                    }}
                >
                    {data.map((el, index) =>
                        <OneNavBar
                            key={index}
                            {...el}
                            width={this.props.width}
                            color={this.props.color}
                            backgroundOfNav={this.props.backgroundOfNav}
                            backgroundColor={this.props.backgroundColor}
                            history={this.props.history}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default MultiNavBar;

class OneNavBar extends Component {
    state = {
        hover: false
    }

    onMouseEnter = () => {
        this.setState({
            hover: true
        })
    }

    onMouseLeave = () => {
        this.setState({
            hover: false
        })
    }

    render() {

        return (
            <div
                style={{
                    padding: '10px 10px 10px 10px',
                    cursor: 'pointer',
                    position: 'relative',

                }}

                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onMouseOver={this.onMouseEnter}
                onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}
            >
                {this.props.title}

                {this.props.hasSubnav && this.state.hover ? (<div
                    style={{
                        backgroundColor: this.props.backgroundColor,
                        position: 'absolute',
                        minWidth: this.props.width,
                        zIndex: 999,
                        display: 'grid',
                        gridTemplateRows: '1fr',
                        boxShadow: '1px 1px 3px rgba(0,0,0,0.3)'
                    }}
                >

                    {this.props.subnav.map((el, index) =>
                        <OneSideNav
                            key={index}
                            {...el}
                            width={this.props.width}
                            color={this.props.color}
                            backgroundOfNav={this.props.backgroundOfNav}
                            backgroundColor={this.props.backgroundColor}
                            history={this.props.history}
                        />
                    )}

                </div>) : null}
            </div>
        )
    }
}


class OneSideNav extends Component {
    state = {
        hover: false
    }
    onMouseEnter = () => {
        this.setState({
            hover: true
        })
    }

    onMouseLeave = () => {
        this.setState({
            hover: false
        })
    }
    render() {
        return (
            <div
                style={{
                    color: this.props.color,
                    position: 'relative',
                    minWidth: this.props.width,
                    padding: '10px',
                    borderBottom: 'solid thin lightgrey',
                    borderRight: 'solid thin lightgrey',
                    boxShadow: '1px 2px 3px rgba(0,0,0,0.3)'
                    
                }}
                onClick={() => !this.props.hasSubnav ? this.props.history.push(this.props.path) : null}

                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                onMouseOver={this.onMouseEnter}
            >
                <font style={{fontSize: '0.8em'}}>{this.props.title}</font>  {this.props.hasSubnav ? (<span
                    className="fa fa-angle-right"
                    style={{ 
                        fontSize: '0.6em', 
                        float: 'right',
                        marginTop: '8px'
                        
                        }}></span>) : null}

                {this.props.hasSubnav && this.state.hover ? (
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 999,
                            left: 0,
                            marginLeft: this.props.width,
                            top: 0,
                            backgroundColor: this.props.backgroundColor
                        }}
                    >
                        {this.props.subnav.map((el, index) =>
                            <OneSideNav
                                key={index}
                                {...el}
                                width={this.props.width}
                                color={this.props.color}
                                backgroundOfNav={this.props.backgroundOfNav}
                                backgroundColor={this.props.backgroundColor}
                                history={this.props.history}
                            />
                        )}
                    </div>
                ) : null}

            </div>
        )
    }
}