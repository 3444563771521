import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, MDBIcon, } from 'mdbreact';
import TitleStyle from './../component/TitleStyle'
import { PostData } from '../api/service';
import Snack from './Snack';

import { ExpansionPanel, Table, TableHead, TableBody, TableRow, TableCell,Button } from '@material-ui/core';
import { ExpansionPanelSummary } from '@material-ui/core';
import { ExpansionPanelDetails } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

const semesters = [
    {
        label: '1st Sem - Group B',
        value: '1B'
    },
    {
        label: '2nd Sem - Group B',
        value: '2B'
    },
    {
        label: '3rd Sem',
        value: '3'
    },
    {
        label: '4th Sem',
        value: '4'
    },
    {
        label: '5th Sem',
        value: '5'
    },
    {
        label: '6th Sem',
        value: '6'
    },
    {
        label: '7th Sem',
        value: '7'
    },
    {
        label: '8th Sem',
        value: '8'
    }
]


export default class CourseMaterialContainer extends Component {
    state = {
        files: [],
        isLoaded: false,
        downloadIndex: ''
    }
    componentDidMount() {
        // fetch files with code AC

        PostData(`/getfilestoresbytype`, { type: 'COM' })
            .then((resp) => {
                console.log("🚀 ~ file: CourseMaterialContainer.js ~ line 59 ~ CourseMaterialContainer ~ .then ~ resp", resp)

                //console.log(resp)
                this.setState({
                    files: resp,
                    isLoaded: true
                })
            })
    }


    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();
        this.setState({
            isDownloading: false
        })




    }

    triggerDownload = (el, index) => {

        this.setState({
            isDownloading: true
        })
        PostData(`/getfiledatafordownloadnew`, el)
            .then((resp) => {

                this.showFile(resp, index)
            })
    }

    render() {
        return (
            <div>
                <Example bread={`Home / Course Materials`} />
                <MDBContainer style={{ paddingTop: '30px' }}>

                    <br />
                    <br />

                    <div>

                        {semesters.map((el, index) =>
                            <ExpansionPanel key={index} expanded>
                                <ExpansionPanelSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography >{el.label}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>Subject</TableCell>
                                                <TableCell>Faculty</TableCell>
                                                <TableCell>File Title</TableCell>
                                                <TableCell>File Link</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(this.state.files.filter((el1) => el1.semester === el.value)) && this.state.files.filter((el1) => el1.semester === el.value).map((el1,index1) => <TableRow key={index1}>
                                                <TableCell>{index1+1}</TableCell>
                                                <TableCell>{el1.subject}</TableCell>
                                                <TableCell>{el1.by_faculty}</TableCell>
                                                <TableCell>{el1.name}</TableCell>
                                                <TableCell>
                                                {el1.file.map((el2, index2) => <Button
                                                key={index2}
                                                variant="text"
                                                color="primary"
                                                onClick={this.triggerDownload.bind(this, el2, index2)}
                                            >
                                                {this.state.downlaodIndex === index2 ? (<i className="fa fa-spinner fa-spin"></i>) : (<MDBIcon icon="file-pdf" />)} &nbsp; Click here to download
                                            </Button>)}
                                                </TableCell>
                                            </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )}













                    </div>

                    <br />
                    <br />


                </MDBContainer>
            </div>
        )
    }
}


