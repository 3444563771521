import React, { Component } from 'react';
import { Container } from 'mdbreact';

class ComingSoon extends Component {
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Container align="center" style={{padding: '20px'}}>
                    <img
                        style={{width: '20%'}}
                        src="https://www.pinclipart.com/picdir/big/348-3481044_website-under-construction-png-icon-under-construction-png.png"
                    />
                    <h3>UNDER CONSTRUCTION</h3>
                    <h6>This page is under construction process, please visit us later</h6>
                </Container>
            </div>
        );
    }
}

export default ComingSoon;