import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class Po extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / PO`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
        <TitleStyle
            title={`Program Outcomes (POs)`}
          />
          {/* <p align="justify" style={{ fontSize: '1.1em' }}>
            The department of Electronics & Telecommunication Engineering was established in the year 1983. Presently, it offers Bachelor of Engineering (B.E.) course in Electronics & Telecommunication Engineering with an annual intake capacity of 60. The syllabi of the B.E. course cover all major thrust areas in electronics and communication technology and also in the allied fields. The department has been always attracting quality students through the Common Entrance Examination conducted by Government of Assam. It has been always an endeavour for the teachers of this department to impart quality knowledge to the students such that as an engineer they can contribute accountably to the technological world as well as for the betterment of the society.
          </p>
          <br /> */}

        


             
          




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Po)