import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import './css/footer.css';
import { WebCounter } from './helper/WebCounter';
import FeedbackContainer from './FeedbackContainer';


const FooterPage = () => {
  return (
    <div>
      <FeedbackContainer />
      <br />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.728347450802!2d91.65986240315569!3d26.140398923744854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a4555cf6051a5%3A0x64825bdb6f421d41!2sAssam%20Engineering%20College%2CElectronics%20and%20Telecommunication%20Engineering%20department!5e0!3m2!1sen!2sin!4v1667460087464!5m2!1sen!2sin"
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: 0 }} allowFullScreen></iframe>
      <MDBFooter style={{ backgroundColor: '#2f2f2f' }} className="font-small pt-4">
        <MDBContainer className="text-md-left">
          <MDBRow>
            <MDBCol md="12" lg="12" sm="12" xs="12" align="center">
              <h4 className="title">Contact Us</h4>
              <p>
                Department of Electronics and Telecommunication Engineering,
                Assam Engineering College,
                Jalukbari Guwahati-781013
                <br />
                Email: <a href="mailto:head.ete@aec.ac.in">head.ete@aec.ac.in</a>
              </p>
              <WebCounter />
            </MDBCol>

            {/* <MDBCol md="8" lg="8" align="right">
            <h5 className="title">How to Reach</h5>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1790.8181672208868!2d91.65766785717817!3d26.143397937399687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a45d6aa8eb949%3A0x14dd0b038766afb9!2sElectronics+and+Computer+Science+Department%2C+Assam+Engineering+College!5e0!3m2!1sen!2sin!4v1556876356473!5m2!1sen!2sin"
              width="100%"
              height="200"
              frameBorder="0"
              style={{ border: 0 }} allowFullScreen></iframe>
          </MDBCol> */}
          </MDBRow>
        </MDBContainer>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright: <a href="https://www.Corexx.in"> Corexx.in </a>
          </MDBContainer>
        </div>
      </MDBFooter >
    </div>
  );
}

export default FooterPage;

