import React from 'react'
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Example from '../component/RecentNews'
import { PostData } from '../api/service';


export default function PlacementContainer() {

    const [data, setData] = React.useState([]);
    const [keys, setKeys] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false)

    React.useEffect(() => {

        PostData(`/getfilestoresbytypep`, {})
        .then((resp) => {
            console.log("🚀 ~ file: PlacementContainer.js ~ line 20 ~ .then ~ resp", resp)
            setData(resp);
            setIsLoaded(true)
        })
        
    }, [])
        
    return (
        <div>
            <Example bread={`Home / Placements`} />
            {isLoaded && <Container style={{ padding: '20px' }}>
                {Array.isArray(data) && data.map((el, index) =>

                    <div key={index} style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', padding: '10px', borderLeft: `solid 1px lightblue` }}>
                        <Typography variant="h6" style={{ fontWeight: 900 }} gutterBottom>{el.batch}</Typography>
                        <ShowTable
                            data={el.data}
                        />

                        <br />
                    </div>

                )}

            </Container>}
        </div>
    )
}


function ShowTable({ data }) {
    return (
        <div  style={{overflowX: 'scroll'}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Roll No</TableCell>
                        <TableCell>Employer</TableCell>
                        <TableCell>--</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(data) && data.map((el, index) =>
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{el.roll_no}</TableCell>
                            <TableCell>{el.employer}</TableCell>
                            <TableCell>
                                {Array.isArray(el.file) && el.file.map((el1,index1) => <img
                                    src={el1.url}
                                    style={{width: 50}}
                                />)}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}
