import React, { Component } from 'react';


class OfficeStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffList: []
        }
    }

    componentDidMount() {
        let initialState = [];
        initialState = [
            {
                "id": "1",
                "name": "Mr. Dhaneswer kalita",
                "designation": "U.D. Assistant",
                "section": "-",
                "phone": "8822424711 ",
                "email": "-"
            },
            {
                "id": "2",
                "name": "Mr. Tilak Ch. Baruah",
                "designation": "U.D. ASSISTANT",
                "section": "-",
                "phone": "-",
                "email": "-"
            },
            {
                "id": "3",
                "name": "Mr. Niren Kalita",
                "designation": "L.D. ASSISTANT",
                "section": "-",
                "phone": "-",
                "email": "-"

            },
            {
                "id": 4,
                "name": "Mr. Dhruba Jyoti Kakoti",
                "designation": "L.D. ASSISTANT",
                "section": "-",
                "phone": "-",
                "email": ""

            },
            {
                "id": 5,
                "name": "Mr. Amrit Kalita",
                "designation": "L.D. ASSISTANT",
                "section": "",
                "phone": "",
                "email": ""

            },
            
     ];

        this.setState({
            staffList: initialState
        });
    }
    render() {
        let persons = this.state.staffList;
        let items = persons.map((person) =>
            <tr key={person.id}>
                <td>{person.name}</td>
                <td>{person.designation}</td>
                {/* <td>{person.section}</td> */}
                <td>{person.phone}</td>
                <td>{person.email}</td>
            </tr>
        );
        return (
            <div className="container">
                <div style={{textAlign:'center',paddingTop:'5vh', color:'blue'}}><h4>OFFICE STAFF</h4></div>
                <br></br>
                <br></br>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr className="bg-primary text-white">
                            <td>Name</td>
                            <td>Designation</td>
                            {/* <td>Section</td> */}
                            <td>Phone</td>
                            <td>Email</td>
                        </tr>
                    </thead>

                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default OfficeStaff;