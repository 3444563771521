import React, { Component } from 'react'
import NavBar from '../component/HeaderComponent/NavBar';

import RecentNews from '../component/RecentNews';
import Abouts from '../component/Abouts';
import FooterPage from '../component/FooterPage';
import Fleshnew from '../component/Fleshnew';
import FeedbackContainer from '../component/FeedbackContainer';


export default class HomepageContainer extends Component {
  render() {
    return (
      <div>
          <RecentNews />
          <br />
          <Abouts />
          <br />

         
      </div>
    )
  }
}
