import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, Table, MDBIcon, } from 'mdbreact';
import TitleStyle from './../component/TitleStyle'
import { PostData } from '../api/service';
import Snack from './Snack';

const syllabus = [
    {
        "id": "39",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "1st Semester - Group B",
        "title": "1st Semester - Group B",
        "filepath": "1-B.pdf"
    },
    {
        "id": "42",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "2nd Semester - Group B",
        "title": "2nd Semester - Group B",
        "filepath": "2-B.pdf"
    },
    {
        "id": "43",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "3rd Semester",
        "title": "3rd Semester",
        "filepath": "3ete.pdf"
    },
    {
        "id": "44",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "4th Semester",
        "title": "4th Semester",
        "filepath": "4ete.pdf"
    },
    {
        "id": "45",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "5th Semester",
        "title": "5th Semester",
        "filepath": "5ete.pdf"
    },
    {
        "id": "46",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "6th Semetser",
        "title": "6th Semetser",
        "filepath": "6ete.pdf"
    },
    {
        "id": "70",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "7th Semester",
        "title": "7th Semester",
        "filepath": "7ete.pdf"
    },
    {
        "id": "71",
        "dept_id": "5",
        "dept_code": "ete",
        "semester": "8th Semester",
        "title": "8th Semester",
        "filepath": "8ete.pdf"
    }
]


const sub_type = [
    {
        label: 'COs',
        value: 'CO'
    },
    {
        label: 'CO-PO table',
        value: 'COPO'
    },
    {
        label: 'Course Plan',
        value: 'COPL'
    },
    {
        label: 'Syllabus',
        value: 'COSYL'
    },
    {
        label: 'Course Material',
        value: 'COSYM'
    },

]

const semesters = [
    {
        label: '1st Sem - Group B',
        value: '1B'
    },
    {
        label: '2nd Sem - Group B',
        value: '2B'
    },
    {
        label: '3rd Sem',
        value: '3'
    },
    {
        label: '4th Sem',
        value: '4'
    },
    {
        label: '5th Sem',
        value: '5'
    },
    {
        label: '6th Sem',
        value: '6'
    },
    {
        label: '7th Sem',
        value: '7'
    },
    {
        label: '8th Sem',
        value: '8'
    }
]


export default class BtechContainer extends Component {
    state = {
        files: [],
        isLoaded: false,
        downloadIndex: ''
    }
    componentDidMount() {
        // fetch files with code AC

        PostData(`/getfilestoresbytype`, { type: 'SYLL' })
            .then((resp) => {
                
                //console.log(resp)
                this.setState({
                    files: resp,
                    isLoaded: true
                })
            })
    }



    getTableData = (semester, sub_type) => {
        let data = this.state.files;
        let r = data.filter(el => el.semester == semester && el.sub_type == sub_type);
        return <ExpanDiv
            title={`Expand List`}
            list={r}
        />
    }
    render() {
        return (
            <div>
                <Example bread={`Home / Academics / B. Tech. Syllabus`} />
                <MDBContainer style={{ paddingTop: '30px' }}>

                    <br />
                    <br />

                    <div>


                        <Table bordered striped >
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th width="15%" style={{ fontSize: '1em', }}>Semester</th>
                                    <th width="15%" style={{ fontSize: '1em', textAlign: 'center' }}>COs</th>
                                    <th width="15%" style={{ fontSize: '1em', textAlign: 'center' }}>CO-PO Mapping</th>
                                    <th width="15%" style={{ fontSize: '1em', textAlign: 'center' }}>Course Plan</th>
                                    <th width="15%" style={{ fontSize: '1em', textAlign: 'center' }}>Syllabus</th>
                                    {/* <th width="15%" style={{ fontSize: '1em', textAlign: 'center' }}>Course Material</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {semesters.map((el, index) =>

                                    <tr key={index}>
                                        <td style={{ fontSize: '0.9em' }}>
                                            {el.label}
                                        </td>
                                        <td align="left">
                                            {this.getTableData(el.value, 'CO')}
                                        </td>
                                        <td align="left">
                                            {this.getTableData(el.value, 'COPO')}
                                        </td>
                                        <td align="left">
                                            {this.getTableData(el.value, 'COPL')}
                                        </td>
                                        <td align="left">
                                            {this.getTableData(el.value, 'COSYL')}
                                        </td>
                                        {/* <td align="left">
                                            {this.getTableData(el.value, 'COSYM')}
                                        </td> */}
                                    </tr>
                                )}


                            </tbody>
                        </Table>


                        <br />
                        <br />
                        <p style={{fontWeight: 700}}><i>* GU Syllabi is applicable upto 2017-2021 batch</i></p>

                    </div>

                    <br />
                    <br />


                </MDBContainer>
            </div>
        )
    }
}


class ExpanDiv extends Component {

    state = {
        expand: false,
        isDownloading: false
    }

    expandDiv = () => {
        this.setState({
            expand: !this.state.expand
        })
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();
        this.setState({
            isDownloading: false
        })




    }

    triggerDownload = (el, index) => {

        this.setState({
            isDownloading: true
        })
        PostData(`/getfiledatafordownloadnew`, el)
            .then((resp) => {

                this.showFile(resp, index)
            })
    }

    render() {
        return (

            <div>
                {this.state.isDownloading ? (<Snack open={true} message="Downloading . . . ." />) : null}
                <a onClick={this.expandDiv}>{this.props.title} {!this.state.expand ? (<MDBIcon icon="angle-down" />) : (<MDBIcon icon="angle-up" />)}</a>

                {this.state.expand ? (<div>
                    <ul className="list-unstyled" style={{ textAlign: 'left' }}>
                        {this.props.list.map((el, index) =>
                            <li key={index} style={{ color: 'blue', cursor: 'pointer', fontSize: '0.8em' }}
                                
                            //onClick={this.triggerDownload.bind(this, el.file[0])}
                            ><a style={{ color: 'blue', cursor: 'pointer', fontSize: '0.8em' }} target="__blank"
                            href={el.file[0].url !== undefined ? el.file[0].url : `#`}><MDBIcon icon="book" /> {el.name}</a></li>
                        )}
                    </ul>
                </div>) : null}
            </div>

        )
    }

}