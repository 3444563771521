import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, Button } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core'
import { HOD_LIST } from '../component/helper/GlobalVariables';

const facultyData = [
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E., AEC, Ghy',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  },
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E.',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  },
  {
    name: 'P. K. Saikia',
    desg: 'Professor',
    qualification: 'B.E.',
    email: 'pranjalkr.saikia@gmail.com',
    phone: '8876371354',
    specifications: 'Programming',
    cv: '',
    photo: ''
  }
]

const styles = {
  teacherCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f9f9f9',
    marginBottom: '20px',
    padding: '10px'
  },
  leftSide: {
    flexBasis: '80%',
    padding: '0px'
  },
  rightSide: {
    flexBasis: '20%'
  },
  smallText: {
    color: 'grey',
    marginBottom: '5px',
    marginTop: '5px',
    fontSize: '0.9em'
  },
  smallTextH6: {
    marginBottom: '10px'
  }
}

class TeacherCard extends Component {

  state = {
    dp: '',
    cv: '',
    isLoading: false,
    isLoaded: false
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  showFile = (blob) => {



    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);


    var link = document.createElement('a');
    link.href = blobUrl;
    link.download = `${blob.type}_${Math.random() * 1000}.pdf`;
    link.click();




  }



  componentDidMount() {

    let data = this.props.dp;
    if (Array.isArray(data) && data.length > 0) {
      PostData(`/getfiledata`, data[0])
        .then((resp) => {
          this.setState({
            dp: resp,
            isLoaded: true
          })
        })
    }
  }

  downloadCv = () => {
    let cv = this.props.cv;
    this.setState({
      isLoading: true
    })
    if (Array.isArray(cv) && cv.length > 0) {
      PostData(`/getfiledata`, cv[0])
        .then((resp) => {
          //console.log(resp)
          this.showFile(resp)
          this.setState({
            isLoading: false
          })
        })
    }
  }

  showQualifications = (data, type) => {
    return data.filter(el => el.type == type)
  }

  render() {
    let i = '';
    if (this.state.isLoaded) {

      i = `data:${this.state.dp.mime};base64,${this.state.dp.data}`;

    }
    return (
      <div style={styles.teacherCard}>
        <Grid container>
          <Grid item sm="12" lg="4" md="4" xs="12">
            {this.state.isLoaded ? (
              <img
                alt=""
                src={i}
                style={{ width: '70%', height: '70%', boxShadow: '1px 2px 10px rgba(0,0,0,0.3)' }}
              />
            ) : (
              <img
                alt=""
                src={require('./../assets/faculty/default.jpg')}
                style={{ width: '70%', height: '70%', boxShadow: '1px 2px 10px rgba(0,0,0,0.3)' }}
              />
            )}
          </Grid>

          <Grid item sm="12" lg="8" md="8" xs="12">
            <h4>{this.props.name}</h4>

            <h6 style={styles.smallTextH6}>

              {this.props.designation}</h6>

            <h6 style={styles.smallTextH6}>
              <div style={styles.smallText}>
                SPECIALIZATION
              </div>
              {this.props.specialisation}</h6>

            <h6 style={styles.smallTextH6}>
              <div style={styles.smallText}>
                EMAIL
              </div>
              <a href={`mailto:${this.props.email}`}>{this.props.email}</a></h6>
            <div>
              {!this.state.isLoading ? (<a
                size="sm"
                color="primary"
                style={{ cursor: 'pointer', color: 'blue', fontSize: 12 }}
                onClick={this.downloadCv}><u>Click here to view the CV</u></a>
              ) : (
                <div
                  style={{ cursor: 'pointer' }}
                >Downloading....</div>

              )}
            </div>
            {/*  <h6 style={styles.smallTextH6}>
                <div style={styles.smallText}>
                  PHONE
                </div>
                <a href={`tel:${this.props.phone}`}>{this.props.phone}</a></h6> */}

            {/* <MDBCol sm="12" lg="6" md="6" xs="12"> */}
            {/* <h6 style={styles.smallTextH6} >
                <div style={styles.smallText}>
                  TEACHING
                </div>
                </h6> */}

            {/* <MDBRow>
                  <MDBCol lg="6">

                  <h6 style={styles.smallTextH6}>
                      January - June
                  </h6>

                  <ul style={{marginLeft: '30px'}}>
                    {this.showQualifications(this.props.qualification, 'EVEN').map((el, index) =>
                      <li key={index} style={{ fontSize: '0.8em' }}>{el.details}</li>
                    )}
                  </ul>

                 

                  <h6 style={styles.smallTextH6} >
                   
                      July - December
                
                  </h6>

                  <ul style={{ marginLeft: '30px' }}>
                    {this.showQualifications(this.props.qualification,'ODD').map((el,index) => 
                      <li key={index} style={{ fontSize: '0.8em' }}>{el.details}</li>
                    )}
                    
                    
                  </ul>

                </MDBCol>
                </MDBRow> */}

            {/* <h6 style={styles.smallTextH6}> 
                <div style={styles.smallText}>
                  HIGHEST DEGREE RECEIVED
                </div>
                {this.showQualifications(this.props.qualification)}</h6>  */}




            {/* </MDBCol> */}







          </Grid>
        </Grid>


      </div>
    )
  }
}

export default class HodContainer extends Component {
  state = {
    teachers: [],
    isLoaded: true
  }
  componentDidMount() {
    GetData(`/getactiveemployeeforwebsitehods`)
      .then((resp) => {
      console.log("🚀 ~ file: HodContainer.js ~ line 296 ~ HodContainer ~ .then ~ resp", resp)
        
        this.setState({
          teachers: resp,
          isLoaded: true
        })
      }).catch((err) => {
      console.log("🚀 ~ file: HodContainer.js ~ line 303 ~ HodContainer ~ .then ~ err", err)
        
      })

  }

  render() {
    return (
      <div>
        <Example bread={`Home / People / HOD`} />
        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`List of HODs`}
          />
          

          {/* {this.state.isLoaded ? (<Grid container spacing={2}>
            {this.state.teachers.map((el, index) =>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TeacherCard
                  key={index}
                  {...el}
                />
              </Grid>
            )}
          </Grid>) : null} */}


              <br />

              
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {HOD_LIST.map((el,index) => <TableRow>
                      <TableCell>{index+1}</TableCell>
                      <TableCell>{el.name}</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
              </MDBContainer>

</div>
)
}
}
