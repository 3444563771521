

export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://backendete.aec.ac.in/public' : 'http://localhost/etc-aec/api/public';

export function PostData(type, userData) {
    //let Auth = new AuthService();

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'ADMIN'
            }
        })
            .then((resp) => {
                if (!resp.ok) {
                    if (resp.status >= 400 && resp.status < 500) {
                        /* return resp.json().then(data => {
                            let err = { errorMessage: data };
                            alert(err.errorMessage);
                            //logout
                            //Auth.logout();
                            throw err;
                        }) */

                        throw resp.text();
                    }
                    else {
                        /* let err = { errorMessage: "Please try again later" };
                        alert(`Something went Wrong!! Status: ${resp.status}`);
                        //logout
                        //Auth.logout();
                        throw err; */
                        throw resp.text();
                    }
                } else {
                    return resp.json();
                }

            }
            )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


export function GetData(type) {
    //let Auth = new AuthService();

    return new Promise((resolve, reject) => {
        fetch(BaseURL + type, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
   
                'Unit': 'FACULTY'
            }
        })
            .then((resp) => {
                
                    if (!resp.ok) {
                        if (resp.status >= 400 && resp.status < 500) {
                            /* return resp.json().then(data => {
                                let err = { errorMessage: data };
                                alert(err.errorMessage);
                                //logout
                                //Auth.logout();
                                throw err;
                            }) */

                            throw resp.text();
                        }
                        else {
                            /* let err = { errorMessage: "Please try again later" };
                            alert(`Something went Wrong!! Status: ${resp.status}`);
                            //logout
                            //Auth.logout();
                            throw err; */
                            throw resp.text();
                        }
                    } else {
                        return resp.json();
                    }

                
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
               
                reject(error);
            });
    });
}




