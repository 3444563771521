import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBRow, MDBCol, Table, MDBIcon, } from 'mdbreact';
import TitleStyle from './../component/TitleStyle'
import { GetData, PostData } from '../api/service';
import { Typography, Button } from '@material-ui/core'
import Snack from './Snack';

const syllabus = [
    {
        "name": "Academic Calendar 2019",
        "filepath": "AcademicCalendar2019.pdf"
    },
    {
        "name": "Academic Calendar 2018",
        "filepath": "AcademicCalendar2018.pdf"
    }
]


export default class AcademicCalender extends Component {
    state = {
        files: [],
        isLoaded: false,
        isDownloading: false
    }
    componentDidMount() {
        // fetch files with code AC

        PostData(`/getfilestoresbytype`, { type: 'AC' })
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    files: resp,
                    isLoaded: true
                })
            })
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();
        this.setState({
            isDownloading: false
        })




    }

    triggerDownload = (el, index) => {

        this.setState({
            isDownloading: true
        })
        PostData(`/getfiledatafordownloadnew`, el)
            .then((resp) => {

                this.showFile(resp, index)
            })
    }

    render() {
        return (
            <div>
                {this.state.isDownloading ? (<Snack open={true} message="Downloading . . . ." />) : null}
                <Example bread={`Home / Academics / Academic Calendar`} />
                <MDBContainer style={{ paddingTop: '30px', paddingRight: '10vw', paddingLeft: '10vw' }}>

                    <br />
                    <br />

                    <div>


                        {this.state.isLoaded ? (<Table bordered striped >

                            <tbody>
                                {this.state.files.map((el, index) =>

                                    <tr key={index}>
                                        <td style={{ fontSize: '1.1em' }}>
                                            {el.name}
                                        </td>
                                        <td align="center">
                                            {el.file.map((el1, index1) => <Button
                                                key={index1}
                                                variant="text"
                                                color="primary"
                                                onClick={this.triggerDownload.bind(this, el1, index1)}
                                            >
                                                {this.state.downlaodIndex === index1 ? (<i className="fa fa-spinner fa-spin"></i>) : (<MDBIcon icon="file-pdf" />)} &nbsp; Click here to download
                                            </Button>)}


                                        </td>
                                    </tr>
                                )}


                            </tbody>
                        </Table>) : (
                                <Typography variant="body1">
                                    Loading . . .
                            </Typography>
                            )}

                    </div>

                    <br />
                    <br />


                </MDBContainer>
            </div>
        )
    }
}
