import React, { Component } from 'react'
import Example from '../component/RecentNews';
import { MDBContainer, MDBBtn, MDBIcon } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import TitleStyle, { SecondaryStyle } from '../component/TitleStyle';
import { GetData, PostData } from '../api/service';
import {Button} from '@material-ui/core'



export default class BestPracticesContainer extends Component {
    state = {
        files: [],
        isLoaded: false,
        downloadIndex: ''
    }
    componentDidMount() {
        // fetch files with code AC

        PostData(`/getfilestoresbytype`, { type: 'BEST_PRACTICES' })
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    files: resp,
                    isLoaded: true
                })
            })
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();
        this.setState({
            downloadIndex: ''
        })




    }

    triggerDownload = (el, index) => {

        this.setState({
            downloadIndex: index
        })
        PostData(`/getfiledatafordownloadnew`, el)
            .then((resp) => {

                this.showFile(resp, index)
            })
    }
    render() {
        return (
            <div>
                <Example bread={`Home / Academics / Best Practices`} />
                <MDBContainer style={{ padding: '20px' }}>
                    <div>
                        <TitleStyle
                            title={`Best Practices`}
                        />
                        <br />



                    </div>
                    <div>

                       


                        {this.state.isLoaded ? (
                            <div>
                                <MDBTable responsive>
                                    <MDBTableHead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>File</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.files.map((el, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{el.name}</td>
                                                <td>
                                                    {el.file.map((el1, index1) => <Button
                                                        key={index1}
                                                        variant="text"
                                                        color="primary"
                                                        onClick={this.triggerDownload.bind(this, el1, index1)}
                                                    >
                                                        {this.state.downlaodIndex === index1 ? (<i className="fa fa-spinner fa-spin"></i>) : (<MDBIcon icon="file-pdf" />)} &nbsp; Click here to download
                                            </Button>)}
                                                </td>
                                            </tr>
                                        )}
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        ) : null}



                    </div>



                </MDBContainer>
            </div>
        )
    }
}


const TableStyle = (props) => {
    return (
        <div>
            <MDBTable responsive>
                <MDBTableHead>
                    <tr>
                        <th>#</th>
                        <th>Session / Year</th>
                        <th>Title</th>
                        <th>File</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {props.data.map((el, index) =>
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.session}</td>
                            <td>{el.title}</td>
                            <td>{el.file}</td>
                        </tr>
                    )}
                </MDBTableBody>
            </MDBTable>

            <div align="right">
                <Button
                    size="sm"
                    color="primary"

                >
                    Click to get More
              </Button>
            </div>
        </div>
    )
}