import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'

import { withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
    div: {
        textAlign: 'center',
    },
})

class CollaborationsContainer extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentDidMount() {
        GetData(`/getcollaborations`)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        let { classes } = this.props
        return (
            <div>
                <Example bread={`Home / Collaborations`} />

                <MDBContainer style={{ paddingTop: '30px' }}>
                    <TitleStyle
                        title={`Collaborations`}
                    />
                    <br />


                    {this.state.isLoaded && <div>

                        <Table>

                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>Type of Collaboration</TableCell>

                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>Agency</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(this.state.data) && this.state.data.map((el, index) => <TableRow key={index}>

                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.type}</TableCell>
                                    
                                    <TableCell>{el.agency}</TableCell>
                                    

                                </TableRow>)}
                            </TableBody>


                        </Table>
                    </div>}








                </MDBContainer>
            </div>
        )
    }
}

export default withStyles(styles)(CollaborationsContainer)
