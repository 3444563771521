import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
    div: {
        textAlign: 'center',
    },
})

class SponsoredPublications extends Component {
    render() {
        let { classes } = this.props
        return (
            <div>
                <Example bread={`Home / Sponsored Research`} />

                <MDBContainer style={{ paddingTop: '30px' }}>
                    <TitleStyle
                        title={`Sponsored Research`}
                    />
                    <br />





                    <table className="table table-sm table-bordered" cellpadding="7" cellspacing="0" width="616">
                        <tbody>
                            <tr>
                                <td height="16" width="5.084745762711864%">
                                    <p><strong>Sl. No.</strong></p>
                                </td>
                                <td width="29.566854990583803%">
                                    <p><strong>Title of the Project&nbsp;</strong></p>
                                </td>
                                <td width="21.468926553672315%">
                                    <p><strong>Principal Investigator (PI) and CO- PI</strong></p>
                                </td>
                                <td width="21.657250470809792%">
                                    <p><strong>Funding Agency and Year of sanction&nbsp;</strong></p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p><strong>Amount</strong></p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p><strong>Duration</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td height="44" width="5.084745762711864%">
                                    <p>1</p>
                                </td>
                                <td valign="top" width="29.566854990583803%">
                                    <p>A collaborative research project on &quot;Automated Animal Detection and Warning System&quot;</p>
                                </td>
                                <td valign="top" width="21.468926553672315%">
                                    <p>Mr. Nishant Bharti (PI)</p>
                                    <p>Dr. Navajit Saikia (CO-PI)</p>
                                </td>
                                <td valign="top" width="21.657250470809792%">
                                    <p>Assam Science and Technology University under TEQIP-III (2019)</p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p>3.0 Lacs</p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p>1 year</p>
                                </td>
                            </tr>
                            <tr>
                                <td height="53" width="5.084745762711864%">
                                    <p>2</p>
                                </td>
                                <td valign="top" width="29.566854990583803%">
                                    <p>A collaborative research project on &ldquo;Use of Reversible Logic in Hardware Security&rdquo;</p>
                                </td>
                                <td valign="top" width="21.468926553672315%">
                                    <p>Mr. Gunajit Kalita (PI)</p>
                                    <p>Dr. Navajit Saikia (CO-PI)</p>
                                </td>
                                <td valign="top" width="21.657250470809792%">
                                    <p>Assam Science and Technology University under TEQIP-III (2019)</p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p>2.7 Lacs</p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p>1 year</p>
                                </td>
                            </tr>
                            <tr>
                                <td height="44" width="5.084745762711864%">
                                    <p>3</p>
                                </td>
                                <td valign="top" width="29.566854990583803%">
                                    <p>A collaborative research project on &ldquo;Fabrication of sensors to detect hazardous air pollutants for smart cities&rdquo;</p>
                                </td>
                                <td valign="top" width="21.468926553672315%">
                                    <p>Dr. Rashi Borgohain (PI)</p>
                                    <p>Prof. Sunandan Baruah (CO-PI)</p>
                                </td>
                                <td valign="top" width="21.657250470809792%">
                                    <p>Assam Science and Technology University under TEQIP-III (2019)</p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p>3.0Lacs</p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p>1 year</p>
                                </td>
                            </tr>
                            <tr>
                                <td height="42" width="5.084745762711864%">
                                    <p>4</p>
                                </td>
                                <td valign="top" width="29.566854990583803%">
                                    <p>A collaborative research project on&nbsp;&ldquo;Fabrication of biosensors to detect different types of bacteria in water&rdquo;</p>
                                </td>
                                <td valign="top" width="21.468926553672315%">
                                    <p>Dr. Rashi Borgohain (PI)</p>
                                    <p>Prof. Sunandan Baruah (CO-PI)</p>
                                </td>
                                <td valign="top" width="21.657250470809792%">
                                    <p>Assam Science and Technology University under TEQIP-III (2019)</p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p>3.0Lacs</p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p>1 year</p>
                                </td>
                            </tr>
                            <tr>
                                <td height="42" width="5.084745762711864%">
                                    <p>5</p>
                                </td>
                                <td valign="top" width="29.566854990583803%">
                                    <p>A collaborative
                                        project on
                                        "Centre of
                                        Excellence at AEC
                                        in Capacity
                                        Building,
                                        Research &
                                        Development,
                                        Skill Development
                                        in ICT Sector at
                                        North East
                                        Region.</p>
                                </td>
                                <td valign="top" width="21.468926553672315%">
                                    <p>Chief Investigator:
                                        Aditya Kumar Sinha
                                        (C-DAC)
                                    </p>
                                    <p>
                                        Co-Investigators:
                                        Dr. Navajit Saikia</p>
                                    <p>Vineeta Tiwari (CDAC)</p>
                                    <p>
                                        Co-ordinators:
                                        RajashreeKonwar
                                        ParimalWagh (CDAC) </p>

                                </td>
                                <td valign="top" width="21.657250470809792%">
                                    <p>C-DAC (Centre for
                                        Development of
                                        Advanced Computing)
                                        (2019)</p>
                                </td>
                                <td width="10.546139359698682%">
                                    <p>81.0
                                        Lacs </p>
                                </td>
                                <td width="11.67608286252354%">
                                    <p>6
                                        months</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>





                </MDBContainer>
            </div>
        )
    }
}

export default withStyles(styles)(SponsoredPublications)
