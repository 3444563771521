import React from 'react';
import {
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import './newnavbar.css';
import SubNav from './SubNav';

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (window.scrollY < 80) {
      this.setState({
        sticky: false
      })
    } else if (window.scrollY > 80) {
      this.setState({
        sticky: true
      })
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar style={{height: '100px' }} expand="md" fixed={this.state.sticky ? '' : ''}>
          <NavbarBrand href="/">
            <div className="navbar-div">
              <div className="navbar-div-logo">
                <img
                  className="logo-img"
                  src={require('./../../assets/header/logo.jpg')}
                  alt="logo"
                />
              </div>
              
            </div>
            
          </NavbarBrand>
          <div className="navbar-div-text">
          
            <h5 style={{ display: 'block', fontSize: '1.2em', fontWeight: 700 }}>
              Department of
              Electronics and Telecommunication Engineering
                </h5>
                <h5 style={{fontWeight: 500}}>Assam Engineering College</h5>    
            
          </div>
          
        </Navbar>
        <SubNav sticky={this.state.sticky} />
      </div>
    );
  }
}