import React, { Component } from 'react';
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HomePageEvent from '../News/HomePageEvent';


const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'row',

        border: `solid 1px #BE592D`
    },
    cardLeft: {
        backgroundColor: '#BE592D',
        color: '#fff',
        padding: '10px',
        textAlign: 'center',
        flexBasis: '40%'
    },
    cardRight: {
        padding: '10px',
        flexBasis: '60%'


    }
}))

class UpcomingEvents extends Component {
    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} align="center">
                        <Typography
                            variant="overline"
                            style={{ fontSize: '1.1em' }}
                        >
                            Latest Events
                    </Typography>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <HomePageEvent event_type="RECENT_EVENT" />
                       
                    </Grid>
                    
                </Grid>
            </div>
        );
    }
}

export default UpcomingEvents;

export function EventCard({ time1, time2, title, file }) {
    let classes = useStyles();
    return (
        <React.Fragment>
            <Card className={classes.card}>
                <div className={classes.cardLeft}>
                    <Typography
                        variant="caption"
                    >
                        {time1}<br />to<br />{time2}
                    </Typography>
                </div>

                <div className={classes.cardRight}>
                    <Typography
                        variant="subtitle2"
                    >
                        {title}
                    </Typography>
                    <Button

                        href={file}
                        color="primary"
                        size="small"
                    >
                        Brochure
                    </Button>
                </div>
            </Card>
        </React.Fragment>
    )
}